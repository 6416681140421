export const apiUrls = {
  login: "admin/adminLogin",
  join: "user/join",
  aTokenCheck: "token/aTokenCheck",
  rTokenCheck: "token/rTokenCheck",
  tokenCheck: "auth/tokenCheck",
  sendEmail: "help/sendEmail",
  getReferenceList: "board/getReferenceList",
  getReferenceListMain: "board/getReferenceListMain",
  getReferenceDetail: (postId = 1) => `board/${postId}/referenceDetail`,
  deleteReference: "board/deleteReference",
  writeReference: "board/writeReference",
  updateReference: "board/updateReference",
  getTrendList: "board/getTrendList",
  getTrendDetail: (postId = 1) => `board/${postId}/trendDetail`,
  deleteTrend: "board/deleteTrend",
  writeTrend: "board/writeTrend",
  updateTrend: "board/updateTrend",
  getQuestionList: "board/getQuestionList",
  getQuestionDetail: (postId = 1) => `board/${postId}/questionDetail`,
  deleteQuestion: "board/deleteQuestion",
  writeQuestion: "board/writeQuestion",
  writeQuestionComment: "board/writeQuestionComment",
  deleteQuestionComment: "board/deleteQuestionComment",
  updateQuestion: "board/updateQuestion",
  questionDownload: "board/questionDownload",
  checkPw: "board/checkPw",
  getHistory: "history/getHistory",
  saveWriteImg: "board/saveWriteImg",
  getMainBoardList: "board/getMainBoardList",
};
