import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosFunction from "hooks/useAxios";
import moment from "moment";
import "../../assets/css/main/mainBoard.css";

const MainBoard = () => {
  const [questionList, setQuestionList] = useState([]);
  const [trendList, setTrendList] = useState([]);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const getMainBoardInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getMainBoardList,
      requestConfig: null,
    })
      .then((res) => {
        if (res.success) {
          setQuestionList(res.questionList);
          setTrendList(res.trendList);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getMainBoardInfo : ", err);
      });
  };

  useEffect(() => {
    getMainBoardInfo();
  }, []);

  return (
    <div className="container3">
      <div className="content-box">
        <div className="bg-main-board">
          <div className="board-area">
            <div className="board-list">
              <div className="top">
                <div className="title">
                  <span>최신경향 업무사례</span>
                </div>
                <Link to="/trend/case">
                  <div className="sm">
                    <span>더보기+</span>
                  </div>
                </Link>
              </div>
              <div className="rt" />
              <div className="bottom">
                <ul>
                  {trendList?.map((item) => (
                    <li key={item.board_id}>
                      <Link to={`/trend/case/detail/${item.board_id}`}>
                        <div className="board-title">{item.title}</div>
                        <div className="board-date">
                          {moment(item.board_date).format("YYYY/MM/DD")}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="board-list">
              <div className="top">
                <div className="title">
                  <span>Q&A 게시판</span>
                </div>
                <Link to="/support/question">
                  <div className="sm">
                    <span>더보기+</span>
                  </div>
                </Link>
              </div>
              <div className="rt" />
              <div className="bottom">
                <ul>
                  {questionList?.map((item) => (
                    <li key={item.board_id}>
                      <Link to={`/support/question/detail/${item.board_id}`}>
                        <div className="board-title">{item.title}</div>
                        {item.comment_cnt > 0 ? (
                          <div className="reply">
                            <span>답변완료</span>
                          </div>
                        ) : (
                          <div className="ready">
                            <span>답변대기</span>
                          </div>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBoard;
