import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { selectIndex } from "recoil/boardStore";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { bMenus } from "data/businessMenu";
import SelectReferenceBoard from "components/board/selectReferenceBoard";

const ReferenceWrite = () => {
  const [quillValue, setQuillValue] = useState("");
  const { apiUrls, axiosFetch, axiosFetchForm } = useAxiosFunction();
  const userInfo = useRecoilValue(userInfoState);
  const selectedIndex = useRecoilValue(selectIndex);
  const navigate = useNavigate();
  const quillRef = useRef();

  const onWriteModeHandler = () => {
    navigate("/support/reference");
  };

  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const writeReference = (reqData) => {
    reqData.user_id = userInfo.userInfo;
    reqData.content = quillValue.replace(/"/g, '\\"');
    if (quillValue == "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    reqData.category =
      bMenus[selectedIndex.first]?.data[selectedIndex.second]?.id;

    axiosFetch({
      method: "post",
      url: apiUrls.writeReference,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          onWriteModeHandler();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  // 사용하고 싶은 옵션, 나열 되었으면 하는 순서대로 나열
  const toolbarOptions = [
    ["link", "image"],
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ];

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];
      if (file.size > 2 * 1024 * 1024) {
        alert("이미지 파일 크기는 2MB까지만 가능합니다.");
        return;
      } else if (!file.type.match("image/.*")) {
        alert("이미지 파일만 업로드 가능합니다.");
        return;
      }

      // multer에 맞는 형식으로 데이터 만들어준다.
      const formData = new FormData();
      formData.append("img", file); // formData는 키-밸류 구조

      // 백엔드 multer라우터에 이미지를 보낸다.
      axiosFetchForm({
        method: "post",
        url: apiUrls.saveWriteImg,
        formData: formData,
      })
        .then((res) => {
          const IMG_URL = res.url;
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection();
          editor.insertEmbed(range.index, "image", IMG_URL);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  // 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "background",
    "color",
    "link",
    "image",
    "width",
  ];

  useEffect(() => {}, []);

  useEffect(() => {
    console.log("ReferenceWrite useEffect > selectedIndex : ", selectedIndex);
  }, [selectedIndex]);

  return (
    <div className="board-write">
      <SelectReferenceBoard />
      <form className="input-area">
        <InputUnit
          noLable="true"
          label="제목"
          holder="제목을 입력해주세요"
          register={register("title", {
            required: "제목은 필수 입력 값 입니다.",
          })}
          clsName="title"
          errors={formErrors}
        />
        <ReactQuill
          className="writeContent"
          placeholder="내용을 입력해주세요."
          {...register("content")}
          theme="snow"
          value={quillValue}
          onChange={setQuillValue}
          required="내용은 필수 입력 값 입니다."
          modules={modules}
          formats={formats}
          ref={quillRef}
        />
      </form>
      <div className="board-btn-area">
        <button
          type="submit"
          className="write-btn"
          onClick={handleSubmit(writeReference)}
        >
          등록
        </button>
        <button
          type="button"
          className="write-btn"
          onClick={onWriteModeHandler}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default ReferenceWrite;
