import React from "react";
import { bMenus } from "data/businessMenu";
import { selectIndex } from "recoil/boardStore";
import { useRecoilState } from "recoil";
import Select from "react-select";

function SelectReferenceBoard() {
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectIndex);

  const firstSelectChange = (e) => {
    setSelectedIndex({ first: e.value, second: 0 });
  };

  const subSelectChange = (e) => {
    setSelectedIndex({ first: selectedIndex.first, second: e.value });
  };

  return (
    <>
      <div className="select-box">
        <Select
          onChange={(e) => {
            firstSelectChange(e);
          }}
          value={bMenus.filter(
            (option) => option.label === bMenus[selectedIndex.first].label
          )}
          options={bMenus}
        ></Select>
        {bMenus[selectedIndex.first].data[0]?.id === 0 ? null : (
          <Select
            onChange={subSelectChange}
            value={bMenus[selectedIndex.first].data.filter(
              (option) =>
                option.label ===
                bMenus[selectedIndex.first].data[selectedIndex.second].label
            )}
            options={bMenus[selectedIndex.first].data}
          ></Select>
        )}
      </div>
    </>
  );
}

export default SelectReferenceBoard;
