import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import ReferenceList from "pages/clientSupport/reference/referenceList";
import ContentNav from "components/common/contentNav";
import "assets/css/clientSupport/board.css";

function ReferenceBoard() {
  const location = useLocation();

  return (
    <>
      <ContentNav />
      <div className="container">
        <div className="content-box">
          <div>
            {location.pathname.includes("detail") ? (
              <Outlet />
            ) : location.pathname.includes("write") ? (
              <Outlet />
            ) : location.pathname.includes("edit") ? (
              <Outlet />
            ) : (
              <ReferenceList />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReferenceBoard;
