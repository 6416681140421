import React, { useState, useRef, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { formats, toolbarOptions } from "hooks/quillOptions";
import { CustomRegExp } from "helpers/customRegExp";

const QuestionWrite = () => {
  const [quillValue, setQuillValue] = useState("");
  const { apiUrls, axiosFetch, axiosFetchForm } = useAxiosFunction();
  const navigate = useNavigate();
  const quillRef = useRef();
  const onWriteModeHandler = () => {
    navigate("/support/question");
  };
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });
  const [attRegister, setAttRegister] = useState(0);
  const [attList, setAttList] = useState([
    {
      attKey: 0,
      label: "파일",
      type: "file",
      register: "attached_file_0",
      errors: formErrors,
    },
  ]);

  const writeQuestion = (reqData) => {
    reqData.content = quillValue.replace(/"/g, '\\"');
    if (quillValue == "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    const formData = new FormData();
    if (reqData.attached_file[0] !== undefined) {
      formData.append("attached_file", reqData.attached_file[0]);
    }
    formData.append("board_pw", reqData.board_pw);
    formData.append("content", reqData.content);
    formData.append("customer_email", reqData.customer_email);
    formData.append("customer_name", reqData.customer_name);
    formData.append("customer_phone", reqData.customer_phone);
    formData.append("title", reqData.title);

    axiosFetchForm({
      method: "post",
      url: apiUrls.writeQuestion,
      formData: formData,
    })
      .then((res) => {
        if (res.success) {
          onWriteModeHandler();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];
      if (file.size > 2 * 1024 * 1024) {
        alert("이미지 파일 크기는 2MB까지만 가능합니다.");
        return;
      } else if (!file.type.match("image/.*")) {
        alert("이미지 파일만 업로드 가능합니다.");
        return;
      }

      // multer에 맞는 형식으로 데이터 만들어준다.
      const formData = new FormData();
      formData.append("img", file); // formData는 키-밸류 구조

      // 백엔드 multer라우터에 이미지를 보낸다.
      axiosFetchForm({
        method: "post",
        url: apiUrls.saveWriteImg,
        formData: formData,
      })
        .then((res) => {
          const IMG_URL = res.url;
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection();
          editor.insertEmbed(range.index, "image", IMG_URL);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  useEffect(() => {
    if (attList.length == 0) {
      setAttList([
        {
          attKey: 0,
          label: "파일",
          type: "file",
          register: "attached_file_0",
          errors: formErrors,
        },
      ]);
    }
  }, [attList]);

  return (
    <div className="board-write">
      <form className="input-area">
        <InputUnit
          label="이름"
          holder="이름을 입력해주세요"
          register={register("customer_name", {
            required: "이름은 필수 입력 값 입니다.",
            pattern: CustomRegExp("name"),
          })}
          errors={formErrors}
          iMaxlength={10}
        />

        <InputUnit
          label="패스워드"
          type="password"
          holder="패스워드를 입력해주세요"
          register={register("board_pw", {
            required: "패스워드는 필수 입력 값 입니다.",
            minLength: CustomRegExp("password"),
          })}
          errors={formErrors}
          iMaxlength={15}
        />

        <InputUnit
          label="이메일"
          holder="이메일을 입력해주세요"
          register={register("customer_email", {
            required: "이메일은 필수 입력 값 입니다.",
            pattern: CustomRegExp("email"),
          })}
          errors={formErrors}
          iMaxlength={100}
        />

        <InputUnit
          label="연락처"
          holder="연락처를 '-' 없이 입력해주세요."
          register={register("customer_phone", {
            required: "연락처는 필수 입력 값 입니다.",
            pattern: CustomRegExp("tel"),
          })}
          errors={formErrors}
          iMaxlength={12}
        />

        <InputUnit
          label="제목"
          holder="제목을 입력해주세요"
          register={register("title", {
            required: "제목은 필수 입력 값 입니다.",
          })}
          clsName="title"
          errors={formErrors}
          iMaxlength={50}
        />
        <ReactQuill
          className="writeContent"
          placeholder="내용을 입력해주세요."
          {...register("content")}
          theme="snow"
          onChange={setQuillValue}
          required="내용은 필수 입력 값 입니다."
          modules={modules}
          formats={formats}
          ref={quillRef}
        />
        <InputUnit
          label="파일첨부"
          type="file"
          register={register("attached_file")}
          errors={formErrors}
        />
      </form>

      <div className="board-btn-area">
        <button
          type="submit"
          className="write-btn"
          onClick={handleSubmit(writeQuestion)}
        >
          등록하기
        </button>
        <button
          type="button"
          className="write-btn"
          onClick={onWriteModeHandler}
        >
          취소하기
        </button>
      </div>
    </div>
  );
};

export default QuestionWrite;
