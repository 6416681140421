import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { selectIndex } from "recoil/boardStore";
import useAxiosFunction from "hooks/useAxios";
import { bMenus } from "data/businessMenu";
import SelectReferenceBoard from "components/board/selectReferenceBoard";

function ReferenceList() {
  const userInfo = useRecoilValue(userInfoState);
  const selectedIndex = useRecoilValue(selectIndex);
  const [referenceResult, setReferenceResult] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const { axiosFetch, apiUrls } = useAxiosFunction();

  const getReferenceInfo = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.getReferenceList,
      requestConfig: {
        currentNavPage: reqData?.currntNavPage,
        category: reqData?.category,
      },
    })
      .then((res) => {
        if (res.success) {
          setReferenceResult(res.result);
          setReferenceList(res.referenceList);
          setPageList(res.pageList);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    getReferenceInfo({
      currntNavPage: 1,
      category: bMenus[selectedIndex.first]?.data[selectedIndex.second]?.id,
    });
  }, [selectedIndex]);

  return (
    <>
      <div className="board-list">
        <SelectReferenceBoard />
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {referenceResult.totalrows}
            <span>건의 게시물</span>
          </div>

          <div className="board-write-btn">
            {userInfo.userInfo && (
              <Link to={`/support/reference/write`}>
                <button className="write-btn">작성하기</button>
              </Link>
            )}
          </div>
        </div>
        <ul className="boardList">
          <li className="head">
            <div className="board-num">
              <strong>No.</strong>
            </div>
            <div className="board-subj">
              <strong>제목</strong>
            </div>
            <div className="board-writer">
              <strong>작성자</strong>
            </div>
            <div className="board-date">
              <strong>작성일</strong>
            </div>
            <div className="board-count">
              <strong>조회수</strong>
            </div>
          </li>
          {referenceList?.map((item, index) => (
            <li key={index}>
              <Link to={`/support/reference/detail/${item.board_id}`}>
                <div className="board-num">{item.row_num}</div>
                <div className="board-subj">
                  <div className="writer">{item.title}</div>
                  {item.isNew ? (
                    <div className="new">
                      <img src="/assets/images/sub/newIco.png" alt="new" />
                    </div>
                  ) : null}
                </div>
                <div className="board-writer">관리자</div>
                <div className="board-date">
                  {moment(item.board_date).format("YYYY/MM/DD")}
                </div>
                <div className="board-count">{item.read_count}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <ul className="paging">
        <li>
          <Link
            onClick={() =>
              getReferenceInfo({
                currntNavPage:
                  referenceResult.currentPage - 10 < 1
                    ? 1
                    : referenceResult.currentPage - 10,
                category:
                  bMenus[selectedIndex.first]?.data[selectedIndex.second]?.id,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/icons/boardFirst.png" alt="이전" />
          </Link>
        </li>
        {pageList?.map((item, index) => (
          <li
            key={index}
            className={item === referenceResult.currentPage ? "on" : null}
          >
            <Link
              onClick={() =>
                getReferenceInfo({
                  currntNavPage: item,
                  category:
                    bMenus[selectedIndex.first]?.data[selectedIndex.second]?.id,
                })
              }
              style={{ cursor: "pointer" }}
            >
              {item}
            </Link>
          </li>
        ))}

        <li>
          <Link
            onClick={() =>
              getReferenceInfo({
                currntNavPage:
                  pageList[pageList.length - 1] + 1 < referenceResult.maxPage
                    ? pageList[pageList.length - 1] + 1
                    : pageList[pageList.length - 1],
                category:
                  bMenus[selectedIndex.first]?.data[selectedIndex.second]?.id,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/icons/boardLast.png" alt="다음" />
          </Link>
        </li>
      </ul>
    </>
  );
}

export default ReferenceList;
