import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import QuestionList from "pages/clientSupport/question/questionList";
import ContentNav from "components/common/contentNav";
import "assets/css/clientSupport/board.css";

function QuestionBoard() {
  const location = useLocation();

  return (
    <>
      <ContentNav />
      <div className="container">
        <div className="content-box">
          {location.pathname.includes("detail") ? (
            <Outlet />
          ) : location.pathname.includes("write") ? (
            <Outlet />
          ) : location.pathname.includes("edit") ? (
            <Outlet />
          ) : location.pathname.includes("boardPw") ? (
            <Outlet />
          ) : (
            <QuestionList />
          )}
        </div>
      </div>
    </>
  );
}

export default QuestionBoard;
