import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import "react-quill/dist/quill.snow.css";
import { formats, toolbarOptions } from "hooks/quillOptions";
import { CustomRegExp } from "helpers/customRegExp";

const QuestionEdit = () => {
  const [quillValue, setQuillValue] = useState("");
  const [questionData, setQuestionData] = useState();
  const { apiUrls, axiosFetch, axiosFetchForm, axiosFetchDown } =
    useAxiosFunction();
  const navigate = useNavigate();
  const quillRef = useRef();
  const { postId } = useParams();
  const [delAttachedStatus, setDelAttachedStatus] = useState(false);

  const onWriteModeHandler = () => {
    navigate("/support/question");
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
  } = useForm({ mode: "onChange" });

  const questionDetail = () => {
    axiosFetch({
      method: "get",
      url: apiUrls.getQuestionDetail(postId),
    })
      .then((res) => {
        if (res.success) {
          setQuestionData(res.result);
          setValue("customer_name", res.result.customer_name);
          setValue("board_pw", res.result.board_pw);
          setValue("customer_email", res.result.customer_email);
          setValue("customer_phone", res.result.customer_phone);
          setValue("title", res.result.title);
          setQuillValue(res.result.content);
        }
      })
      .catch((err) => {
        console.log("[ERROR] get question detail : ", err);
      });
  };

  const writeQuestion = (reqData) => {
    reqData.content = quillValue.replace(/"/g, '\\"');
    if (quillValue == "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    const formData = new FormData();
    if (reqData.attached_file[0] != undefined) {
      formData.append("attached_file", reqData.attached_file[0]);
    }
    formData.append("board_id", postId);
    formData.append("board_pw", reqData.board_pw);
    formData.append("content", reqData.content);
    formData.append("customer_email", reqData.customer_email);
    formData.append("customer_name", reqData.customer_name);
    formData.append("customer_phone", reqData.customer_phone);
    formData.append("title", reqData.title);
    formData.append("del_attached_status", delAttachedStatus);

    axiosFetchForm({
      method: "post",
      url: apiUrls.updateQuestion,
      formData: formData,
    })
      .then((res) => {
        if (res.success) {
          onWriteModeHandler();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];

      console.log("file > ", file);

      if (file.size > 2 * 1024 * 1024) {
        alert("이미지 파일 크기는 2MB까지만 가능합니다.");
        return;
      } else if (!file.type.match("image/.*")) {
        alert("이미지 파일만 업로드 가능합니다.");
        return;
      }

      // multer에 맞는 형식으로 데이터 만들어준다.
      const formData = new FormData();
      formData.append("img", file); // formData는 키-밸류 구조

      // 백엔드 multer라우터에 이미지를 보낸다.
      axiosFetchForm({
        method: "post",
        url: apiUrls.saveWriteImg,
        formData: formData,
      })
        .then((res) => {
          const IMG_URL = res.url;
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection();
          editor.insertEmbed(range.index, "image", IMG_URL);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  const deleteAttached = () => {
    setDelAttachedStatus(true);
  };

  useEffect(() => {
    questionDetail();
  }, []);

  return (
    <div className="board-write">
      <form className="input-area">
        <InputUnit
          label="이름"
          holder="이름을 입력해주세요"
          register={register("customer_name", {
            required: "이름은 필수 입력 값 입니다.",
            pattern: CustomRegExp("name"),
          })}
          errors={formErrors}
          iMaxlength={10}
        />

        <InputUnit
          label="이메일"
          holder="이메일을 입력해주세요"
          register={register("customer_email", {
            required: "이메일은 필수 입력 값 입니다.",
            pattern: CustomRegExp("email"),
          })}
          errors={formErrors}
          iMaxlength={100}
        />

        <InputUnit
          label="연락처"
          holder="'-' 없이 입력해주세요."
          register={register("customer_phone", {
            required: "연락처는 필수 입력 값 입니다.",
            pattern: CustomRegExp("tel"),
          })}
          errors={formErrors}
          iMaxlength={12}
        />

        <InputUnit
          label="제목"
          holder="제목을 입력해주세요"
          register={register("title", {
            required: "제목은 필수 입력 값 입니다.",
          })}
          clsName="title"
          errors={formErrors}
          iMaxlength={50}
        />
        <ReactQuill
          className="writeContent"
          placeholder="내용을 입력해주세요."
          {...register("content")}
          theme="snow"
          value={quillValue}
          onChange={setQuillValue}
          required="내용은 필수 입력 값 입니다."
          modules={modules}
          formats={formats}
          ref={quillRef}
        />

        {questionData?.origin_file != null && !delAttachedStatus && (
          <div className="detail-content-box">
            <div className="board-content">
              <div className="board-comment">
                <div className="rt2" />
                <div className="title">
                  <span>첨부파일</span>
                </div>
                <div className="rt1" />
                <div className="file">
                  <img src="/assets/images/common/download.png" />
                  <span>{questionData?.origin_file}</span>
                  <button type="button" onClick={deleteAttached}>
                    삭제
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {delAttachedStatus || questionData?.origin_file == null ? (
          <InputUnit
            label="파일첨부"
            type="file"
            register={register("attached_file")}
            errors={formErrors}
          />
        ) : null}
      </form>
      <div className="board-btn-area">
        <button
          type="submit"
          className="write-btn"
          onClick={handleSubmit(writeQuestion)}
        >
          수정
        </button>
        <button
          type="button"
          className="write-btn"
          onClick={onWriteModeHandler}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default QuestionEdit;
