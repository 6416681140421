import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { selectIndex } from "recoil/boardStore";
import BusinessContent from "pages/business/businessContents";
import ContentNav from "components/common/contentNav";
import "assets/css/business/business.css";

function Business() {
  const [searchParams] = useSearchParams();
  const [pageData, setPageData] = useState();
  const navigate = useNavigate();
  const setSelectedIndex = useSetRecoilState(selectIndex);
  const id = searchParams.get("id");
  const sub = searchParams.get("sub");

  const goToRefBoard = (e) => {
    e.preventDefault();
    setSelectedIndex({
      first: id,
      second: sub,
    });
    navigate(`/support/reference`);
  };

  useEffect(() => {
    setPageData(BusinessContent[id - 1].subMenu[sub].data);
  }, [id, sub]);

  return (
    <>
      <ContentNav />
      {pageData}
      <div className="container">
        <div className="content-box">
          <div className="bc">
            <div className="ref-area">
              <div className="tx">
                <div className="title">
                  <span>자료실</span>
                </div>
                <div className="sub-title">
                  <span>상기 업무관련 게시판을 참고해주세요.</span>
                </div>
              </div>
              <div className="btn">
                <Link onClick={goToRefBoard}>
                  <span>자료실 바로가기</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Business;
