import { atom } from "recoil";

const userInfoState = atom({
  key: "userInfoState",
  default: {
    aToken: null,
    isLogin: null,
    userInfo: null,
  },
});

// usage
// const charCountState = selector({
//   key: "charCountState", // unique ID (with respect to other atoms/selectors)
//   get: ({ get }) => {
//     const text = get("ATOKEN");
//     return text.length;
//   },
// });

export { userInfoState };
