import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import { naviMenus } from "data/businessMenu";
import Select from "react-select";
import "../../assets/css/common/main.css";
import "../../assets/css/common/contentNav.css";

const ContentNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menus, setMenus] = useState();
  const [subMenu, setSubMenu] = useState("");
  const [midMenu, setMidMenu] = useState();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const sub = searchParams.get("sub");
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };

  const firstSelectChange = (e) => {
    navigate(`/${menus.subMenu.find((x) => x.label === e.label).subLocate}`);
    e.preventDefault();
  };

  const secondSelectChange = (e) => {
    navigate(`/${midMenu.subMenu.find((x) => x.value === e.value).subLocate}`);
    e.preventDefault();
  };

  useEffect(() => {
    if (id) {
      let menuInfo = naviMenus.find((x) => x.mainLocate === "Business");
      let midMenu = menuInfo.subMenu.find((x) => x.id === parseInt(id));
      let subMenu = midMenu.subMenu.find((x) => x.value === parseInt(sub));

      midMenu.subMenu.map((element) => {
        element.isHit = false;
      });

      subMenu.isHit = true;

      setMenus(menuInfo);
      setMidMenu(midMenu);
      setSubMenu(subMenu);
    } else {
      let pathList = location.pathname.split("/");
      let menuInfo = naviMenus.find(
        (x) => x.mainLocate.toLowerCase() === pathList[1]
      );

      menuInfo.subMenu.map((element) => {
        element.isHit = false;
      });

      let subMenu = menuInfo.subMenu.find((x) =>
        x.subLocate.includes(pathList[2])
      );

      subMenu.isHit = true;
      setSubMenu(subMenu);
      setMenus(menuInfo);
    }
  }, [menus, id, sub]);

  return (
    <>
      <div className="container">
        <div className="content-box">
          <div className="navArea">
            <div className="contentNav">
              <div className="info">
                <div className="tx1">
                  <Link to={`/`}>
                    <span>Home</span>
                  </Link>
                </div>
                <div className="el-5"></div>
                <div className="tx1">
                  <span>{menus?.mainLocate}</span>
                </div>
                <div className="el-5"></div>
                {id === null ? (
                  <Select
                    styles={colourStyles}
                    className="react-select-container"
                    onChange={(e) => {
                      firstSelectChange(e);
                    }}
                    value={menus?.subMenu.filter((x) => x.isHit === true)}
                    options={menus?.subMenu}
                  ></Select>
                ) : (
                  <Select
                    styles={colourStyles}
                    className="react-select-container"
                    onChange={(e) => {
                      firstSelectChange(e);
                    }}
                    value={menus?.subMenu.filter(
                      (x) => x.label === menus?.subMenu[id - 1].label
                    )}
                    options={menus?.subMenu}
                  ></Select>
                )}

                {id === null ? null : (
                  <>
                    <div className="el-5"></div>
                    <Select
                      className="react-select-container"
                      onChange={(e) => {
                        secondSelectChange(e);
                      }}
                      value={midMenu?.subMenu.filter(
                        (x) => x.label === midMenu?.subMenu[sub].label
                      )}
                      options={midMenu?.subMenu}
                    ></Select>
                  </>
                )}
              </div>
              <div className="title">
                <span>{subMenu.label}</span>
              </div>
              <div className="link">
                {id === null
                  ? menus?.subMenu?.map((menu, index) => (
                      <li key={index}>
                        <Link to={`/${menu.subLocate}`}>
                          <div>
                            <span
                              className={menu?.isHit === true ? "isHit" : ""}
                            >
                              {menu?.subTitle}
                            </span>
                            {menu?.isHit === true ? (
                              <div className="rect" />
                            ) : null}
                          </div>
                        </Link>
                      </li>
                    ))
                  : midMenu?.subMenu?.map((menu, index) => (
                      <li key={index}>
                        <Link to={`/${menu.subLocate}`}>
                          <div>
                            <span
                              className={menu?.isHit === true ? "isHit" : ""}
                            >
                              {menu?.subTitle}
                            </span>
                            {menu?.isHit === true ? (
                              <div className="rect" />
                            ) : null}
                          </div>
                        </Link>
                      </li>
                    ))}
              </div>
            </div>
            {menus?.bgImage === null ? null : (
              <div className="bg">
                <img src={menus?.bgImage} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="line" />
      </div>
    </>
  );
};

export default ContentNav;
