import React from "react";
import ContentNav from "components/common/contentNav";
import "assets/css/injunglaw/partners.css";

function Partners() {
  const partner_array = [
    {
      title:
        "(주)신영증권, (주)우리투자증권, (주)이연제약, (주)에이인터내셔널 자문(2006년~)",
      sub: "(주)한국태양광, (주)영남태양광, (주)썬웨이태양광,(주)다니엘영동태양광, 탑선태양광(주), 진슬태양광(주) 외 100여개사 설립",
      companys: ["partner_01.png", "partner_02.png", "partner_03.png"],
      companys_two: ["partner_04.png", "partner_05.png", "partner_06.png"],
    },
    {
      title: "대학기술지주회사 자문위원",
      sub: "서강대, 동국대, 부산대, 인천대, 제주대 등",
      companys: ["partner_07.png", "partner_08.png", "partner_09.png"],
      companys_two: ["partner_10.png", "partner_11.png", ""],
    },
    {
      title: "한국공항공사 감자업무, 인천공항공사창고동 보존등기 수행",
      sub: "",
      companys: ["partner_12.png", "partner_13.png", ""],
      companys_two: null,
    },
    {
      title:
        "SK해운 소규모합병(2008년), (주)메타넷에스엔씨 법인합병 (주)엠, (주)인터캠 법인분할",
      sub: "",
      companys: ["partner_14.png", "partner_15.png", ""],
      companys_two: null,
    },
    {
      title: "(주)에머슨퍼시픽 남해콘도 분양등기수행(2007년)",
      sub: "",
      companys: ["partner_16.png", "", ""],
      companys_two: null,
    },
    {
      title:
        "평생교육진흥원 설립수행, (재)세계여수박람회, (사)한일해저터널연구회 설립수행",
      sub: "",
      companys: ["partner_17.png", "partner_18.png", ""],
      companys_two: null,
    },
    {
      title:
        "사회복지법인 열매나눔재단, 한국컴패션, 사회적기업 나눔클리닉, 고마운 사람등 자문",
      sub: "",
      companys: ["partner_19.png", "partner_20.png", ""],
      companys_two: null,
    },
    {
      title:
        "다국적기업 거래현황(스타츠코리아, 글라막스코리아, 허메스홀딩스, 토다이등)",
      sub: "",
      companys: ["partner_21.png", "", ""],
      companys_two: null,
    },
    {
      title: "민자사업법에 의한 BTL, BTO설립 및 자문(약80여개 회사)",
      sub: "민자사업법에 의한 BTL, BTO설립 및 자문(약80여개 회사) / 현대건설 진행(가야철도, 미래세움, 울산과기대, 대구희망세움) / 코오롱건설(서당마을) 대우조선해양건설(청출람배움터) / 벽산건설(포천미래, 군장신항만건설, 활짝필희망교육등) / 신한건설(행국초관리, 남양주배움터, 경기체육발전)동원건설(진천물자원)",
      companys: ["partner_22.png", "partner_23.png", "partner_24.png"],
      companys_two: ["partner_25.png", "partner_26.png", "partner_27.png"],
    },
    {
      title:
        "기타 (주)메트로신문사, (주)태룡당, (주)예일전자 등 300여개사와 상시 자문 및 업무수행 중",
      sub: "",
      companys: ["partner_28.png", "", ""],
      companys_two: null,
    },
  ];

  return (
    <>
      <ContentNav />
      <div className="container">
        <div className="content-box">
          <div className="partners">
            {partner_array.map((item, index) => (
              <div key={index} className="parters-list-box">
                <div className="partners-title">
                  <div className="partners-title-dot"></div>
                  <span className="partners-title-span">{item.title}</span>
                </div>
                {item.sub != "" && (
                  <div className="partners-sub">
                    <span>{item.sub}</span>
                  </div>
                )}
                <table className="partners-table">
                  <tbody>
                    <tr>
                      {item.companys.map(
                        (subItem, subIndex) =>
                          item.companys[0] != "" && (
                            <td key={subItem}>
                              {subItem !== "" && (
                                <img
                                  src={"/assets/images/injunglaw/" + subItem}
                                />
                              )}
                            </td>
                          )
                      )}
                    </tr>
                    {item.companys_two === null ? null : (
                      <tr>
                        {item.companys_two.map(
                          (subItem, subIndex) =>
                            item.companys_two[0] != "" && (
                              <td key={subItem}>
                                {subItem !== "" && (
                                  <img
                                    src={"/assets/images/injunglaw/" + subItem}
                                  />
                                )}
                              </td>
                            )
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Partners;
