import React, { useState } from "react";
import { Link } from "react-router-dom";
import { naviMenus } from "data/businessMenu";
// import { useInterval } from "hooks/useInterval";
import "assets/css/main/business.css";

function Business() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const bMainMenus = naviMenus.find((x) => x.mainLocate === "Business").subMenu;

  const prevBtnClick = (e) => {
    e.preventDefault();
    if (selectedIndex === 0) {
      setSelectedIndex(bMainMenus.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const nextBtnClick = () => {
    if (selectedIndex === bMainMenus.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const btnClick = (e, value) => {
    e.preventDefault();
    setSelectedIndex(value);
    //window.scrollTo(0, 1500);
  };

  // useInterval(() => {
  //   nextBtnClick();
  // }, 10000);

  return (
    <div className="container">
      <div className="content-box">
        <div className="business">
          <div className="content">
            <div className="bg">
              <img src="assets/images/common/background04.jpg" />
            </div>
            <div className="txArea">
              <div className="tb1">
                <span>BUSINESS</span>
              </div>
              <div className="tb2">
                <span>{bMainMenus[selectedIndex].label}</span>
              </div>
              <div className="tb3">
                <ul>
                  {bMainMenus[selectedIndex]?.subMenu?.map((item, index) => (
                    <Link to={item.subLocate}>
                      <li key={index}>
                        <span>{item.label}</span>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
              <div className="next">
                <div className="link">
                  <img src="assets/images/icons/business_link.png" />
                </div>
                <img
                  src="assets/images/icons/business_left.png"
                  onClick={prevBtnClick}
                />
                <div className="rt"></div>
                <img
                  src="assets/images/icons/business_right.png"
                  onClick={nextBtnClick}
                />
              </div>
            </div>
          </div>
          <div className="btnArea">
            <ul className="btnList">
              <li onClick={(e) => btnClick(e, 0)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business01.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>establish</span>
                      </div>
                      <div className="tx2">
                        <span>상사회사설립</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>상사회사설립</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=1&sub=0">
                        <div className="tx2">
                          <span>⦁ 주식회사 설립</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=1&sub=1">
                        <div className="tx2">
                          <span>⦁ 유한회사, 합자회사 설립</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=1&sub=2">
                        <div className="tx2">
                          <span>⦁ 민자사업SPC, 대학기술지주회사</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=1&sub=3">
                        <div className="tx2">
                          <span>⦁ 농업회사법인, 사회적기업법인</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=1&sub=4">
                        <div className="tx2">
                          <span>⦁ 특별법 설립</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=1&sub=5">
                        <div className="tx2">
                          <span>⦁ 외국인 투자법인</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
              <li onClick={(e) => btnClick(e, 1)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business02.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>corporation</span>
                      </div>
                      <div className="tx2">
                        <span>법인전환/출자전환</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>법인전환/출자전환</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=2&sub=0">
                        <div className="tx2">
                          <span>⦁ 현물출자</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=2&sub=1">
                        <div className="tx2">
                          <span>⦁ 사업양도양수</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=2&sub=2">
                        <div className="tx2">
                          <span>⦁ 중소기업통합</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
              <li onClick={(e) => btnClick(e, 2)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business03.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>capital</span>
                      </div>
                      <div className="tx2">
                        <span>자본금 변동</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>자본금 변동</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=3&sub=0">
                        <div className="tx2">
                          <span>⦁ 증자(유상, 무상)</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=3&sub=1">
                        <div className="tx2">
                          <span>⦁ 감자(유상, 무상)</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
              <li onClick={(e) => btnClick(e, 3)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business04.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>merger</span>
                      </div>
                      <div className="tx2">
                        <span>합병/분할/조직변경</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>합병/분할/조직변경</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=4&sub=0">
                        <div className="tx2">
                          <span>⦁ 합병</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=4&sub=1">
                        <div className="tx2">
                          <span>⦁ 분할</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=4&sub=2">
                        <div className="tx2">
                          <span>⦁ 조직변경</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="btnList">
              <li onClick={(e) => btnClick(e, 4)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business05.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>stock</span>
                      </div>
                      <div className="tx2">
                        <span>사채/우선주/스톡옵션</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>사채/우선주/스톡옵션</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=5&sub=0">
                        <div className="tx2">
                          <span>⦁ 조직변경</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=5&sub=1">
                        <div className="tx2">
                          <span>⦁ 우선주, 상환주, 전환주식발행</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=5&sub=2">
                        <div className="tx2">
                          <span>⦁ 주식매수선택권</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
              <li onClick={(e) => btnClick(e, 5)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business06.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>dissolution</span>
                      </div>
                      <div className="tx2">
                        <span>해산/청산/법인부활</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>해산/청산/법인부활</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=6&sub=0">
                        <div className="tx2">
                          <span>⦁ 해산, 청산</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=6&sub=1">
                        <div className="tx2">
                          <span>⦁ 계속, 부활</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
              <li onClick={(e) => btnClick(e, 6)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business07.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>non-profit</span>
                      </div>
                      <div className="tx2">
                        <span>비영리법인</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>비영리법인</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=7&sub=0">
                        <div className="tx2">
                          <span>⦁ 사단, 재단법인</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=7&sub=1">
                        <div className="tx2">
                          <span>⦁ 기타법인</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
              <li onClick={(e) => btnClick(e, 7)}>
                <div className="btn">
                  <Link to="/">
                    <div className="btnCont">
                      <div className="ic">
                        <img src="assets/images/icons/business08.png" alt="" />
                      </div>
                      <div className="tx1">
                        <span>real-estate</span>
                      </div>
                      <div className="tx2">
                        <span>부동산등기</span>
                      </div>
                    </div>
                  </Link>
                  <div className="hoverCont">
                    <li>
                      <div className="tx1">
                        <span>부동산등기</span>
                      </div>
                    </li>
                    <li>
                      <Link to="/business/category?id=8&sub=0">
                        <div className="tx2">
                          <span>⦁ 소유권보존, 이전등기</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=8&sub=1">
                        <div className="tx2">
                          <span>⦁ 상속등기, 외국인 상속</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/category?id=8&sub=2">
                        <div className="tx2">
                          <span>⦁ 근저당권, 전세권, 가등기</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Business;
