import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/main/mainIntro.css";

const MainIntro = () => {
  const navigate = useNavigate();
  const locateQusetion = () => {
    navigate("/support/reservation");
  };
  return (
    <div className="container bg-main-intro">
      <div className="content-box">
        <div className="main-intro">
          <p className="main-intro-top">기업의 든든한 법무 파트너</p>
          <p className="main-intro-title">믿고 신뢰할 수 있는 합동법무사</p>
          <p className="main-intro-sub mt70">
            20년 이상 상사회사 및 기업법무를 수행하면서 쌓은 다양한 노하우를
            고객 여러분과 최선을 다해 공유하고 있습니다.
          </p>
          <p className="main-intro-sub mt50">
            특히 현물출자, 법인합병, 분할, 외국인투자, 전환사채 및 종류주식을
            통한 신주발행 등 특화된 경험치를 바탕으로 귀사에 최적화된 업무를
            지원하고 있습니다.
          </p>
          <p className="main-intro-sub mt50">
            귀사의 든든한 법무 해결사가 되어 드리겠습니다.
          </p>
          <button onClick={locateQusetion} className="main-intro-button">
            1:1문의 바로가기
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainIntro;
