import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { useSetRecoilState } from "recoil";
import { checkPwState } from "recoil/boardStore";
import "assets/css/clientSupport/board.css";

const QuestionBoardPw = ({ postId }) => {
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const setCheckSuccess = useSetRecoilState(checkPwState);

  const onWriteModeHandler = () => {
    navigate("/support/question");
  };

  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
    getValues,
  } = useForm({ mode: "onBlur" });

  const checkPw = (reqData) => {
    reqData.board_id = postId;

    axiosFetch({
      method: "post",
      url: apiUrls.checkPw,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          /* 리코일로 데이터 저장하기 */
          setCheckSuccess(res.success);
        } else {
          alert("비밀번호가 일치하지 않습니다.");
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.preventDefault();
      const pw_value = getValues("board_pw");
      checkPw({ board_pw: pw_value });
    }
  };

  return (
    <div className="board-write">
      <form className="input-area" onKeyDown={(e) => checkKeyDown(e)}>
        <InputUnit
          label="패스워드"
          type="password"
          holder="패스워드를 입력해주세요"
          register={register("board_pw", {
            required: "패스워드는 필수 입력 값 입니다.",
          })}
          errors={formErrors}
        />
      </form>
      <div className="board-btn-area">
        <button
          type="submit"
          className="write-btn"
          onClick={handleSubmit(checkPw)}
        >
          확인
        </button>
        <button
          type="button"
          className="write-btn"
          onClick={onWriteModeHandler}
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default QuestionBoardPw;
