import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useRecoilState } from "recoil";
import { questionPageIndexState } from "recoil/boardStore";
import useAxiosFunction from "hooks/useAxios";

function QuestionList() {
  const [questionPageIndex, setQuestionPageIndex] = useRecoilState(
    questionPageIndexState
  );
  const [questionResult, setQuestionResult] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const getQuestionInfo = (reqData) => {
    setQuestionPageIndex(reqData);

    axiosFetch({
      method: "post",
      url: apiUrls.getQuestionList,
      requestConfig: { currentNavPage: reqData },
    })
      .then((res) => {
        if (res.success) {
          setQuestionResult(res.result);
          setQuestionList(res.questionList);
          setPageList(res.pageList);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    getQuestionInfo(questionPageIndex);
  }, []);

  return (
    <>
      <div className="board-list">
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {questionResult.totalrows}
            <span>건의 게시물</span>
          </div>
          <div className="board-write-btn">
            <Link to={`/support/question/write`}>
              <button className="write-btn">작성하기</button>
            </Link>
          </div>
        </div>
        <ul className="boardList">
          <li className="head">
            <div className="board-num">
              <strong>No.</strong>
            </div>
            <div className="board-comment">
              <strong>답변</strong>
            </div>
            <div className="board-subj">
              <strong>제목</strong>
            </div>
            <div className="board-writer">
              <strong>작성자</strong>
            </div>
            <div className="board-date">
              <strong>작성일</strong>
            </div>
            <div className="board-count">
              <strong>조회수</strong>
            </div>
          </li>
          {questionList?.map((item, index) => (
            <li key={index}>
              <Link to={`/support/question/detail/${item.board_id}`}>
                <div className="board-num">{item.row_num}</div>
                <div className="board-comment">
                  {item.comment_cnt > 0 ? (
                    <div className="comment-complete">
                      <img src="/assets/images/common/check.png" />
                    </div>
                  ) : (
                    <div className="comment-ready">
                      <span>-</span>
                    </div>
                  )}
                </div>
                <div className="board-subj">
                  <div className="writer">
                    <img src="/assets/images/common/lock.png" />
                    {item.title}
                  </div>
                  {item.isNew ? (
                    <div className="new">
                      <img src="/assets/images/sub/newIco.png" />
                    </div>
                  ) : null}
                </div>
                <div className="board-writer">{item.customer_name}</div>
                <div className="board-date">
                  {moment(item.board_date).format("YYYY/MM/DD")}
                </div>
                <div className="board-count">{item.read_count}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <ul className="paging">
        <li>
          <Link
            onClick={() =>
              getQuestionInfo(
                questionResult.currentPage - 10 < 1
                  ? 1
                  : questionResult.currentPage - 10
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/icons/boardFirst.png" alt="다음" />{" "}
          </Link>
        </li>
        {pageList?.map((item, index) => (
          <li
            key={index}
            className={item == questionResult.currentPage ? "on" : null}
          >
            <Link
              onClick={() => getQuestionInfo(item)}
              style={{ cursor: "pointer" }}
            >
              {item}
            </Link>
          </li>
        ))}

        <li>
          <Link
            onClick={() =>
              getQuestionInfo(
                pageList[pageList.length - 1] + 1 < questionResult.maxPage
                  ? pageList[pageList.length - 1] + 1
                  : pageList[pageList.length - 1]
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/icons/boardLast.png" alt="다음" />{" "}
          </Link>
        </li>
      </ul>
    </>
  );
}

export default QuestionList;
