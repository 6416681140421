import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/main/mainQuickMenu.css";

const MainQuickMenu = () => {
  return (
    <div className="container bg-main-quick-menu">
      <div className="content-box">
        <div className="main-quick">
          <p className="main-quick-title">Injung Quick Menu</p>
          <p className="main-quick-sub">
            기업법무의 새로운 패러다임, 인정법무사무소를 빠르게 만나보세요.
          </p>
          <div className="quick-list">
            <Link to={"injung/greetings"}>
              <div className="quick-list-el">
                <div className="el-info">
                  <p className="quick-title">introduce</p>
                  <p className="title-eng">Injung Greeting</p>
                  <p className="title-kor">대표법무사 인사말</p>
                  <p className="quick-detail">
                    인정합동법무사의 대표법무사님의 인사말입니다.
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"injung/direction"}>
              <div className="quick-list-el">
                <div className="el-info">
                  <p className="quick-title">location</p>
                  <p className="title-eng">Injung Location</p>
                  <p className="title-kor">인정 찾아 오시는 길</p>
                  <p className="quick-detail">
                    인정합동법무사 사무실로 오시는 길을 안내해드립니다.
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/trend/case"}>
              <div className="quick-list-el">
                <div className="el-info">
                  <p className="quick-title">Trend</p>
                  <p className="title-eng">Injung Trend</p>
                  <p className="title-kor">최신경향 & 실무사례</p>
                  <p className="quick-detail">
                    최신경향 및 실무사례를 가장 먼저 확인해보세요.
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/support/question"}>
              <div className="quick-list-el">
                <div className="el-info">
                  <p className="quick-title">Customer</p>
                  <p className="title-eng">Counsel</p>
                  <p className="title-kor">상담문의</p>
                  <p className="quick-detail">
                    궁금한것이 있으시다면 언제든지 편하게 문의주세요.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainQuickMenu;
