import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import { useNavigate } from "react-router-dom";
import InputUnit from "components/InputUnit";
import { CustomRegExp } from "helpers/customRegExp";
import ContentNav from "components/common/contentNav";
import "../../../assets/css/clientSupport/reservation.css";

function Reservation() {
  const [textValue, setTextValue] = useState("");
  const navigate = useNavigate();

  const { apiUrls, axiosFetch } = useAxiosFunction();
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const onMainHandler = () => {
    navigate("/");
  };

  const sendEmail = (reqData) => {
    console.log(JSON.stringify(reqData));

    axiosFetch({
      method: "post",
      url: apiUrls.sendEmail,
      requestConfig: reqData,
    })
      .then((res) => {
        alert(res.message);
        navigate(0);
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };

  const handleSetTab = (e) => {
    console.log(e.keyCode);
    if (e.keyCode === 9) {
      e.preventDefault();
      let val = e.target.value;
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      e.target.value = val.substring(0, start) + "\t" + val.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
      handleSetValue(e);
      return false; //  prevent focus
    }
  };

  return (
    <>
      <ContentNav />
      <div className="container">
        <div className="content-box">
          <div className="reservation">
            <p className="reservation-title">
              ⦁ 문의를 남겨주시면 최대한 빠르게 답변드리겠습니다.
            </p>
            <form className="input-area">
              <InputUnit
                clsName="name"
                holder="이름"
                register={register("customer_name", {
                  required: "이름은 필수 입력 값 입니다.",
                  pattern: CustomRegExp("name"),
                })}
                errors={formErrors}
                iMaxlength={10}
              />

              <InputUnit
                holder="전화번호 '-'없이 입력해주세요."
                register={register("customer_phone", {
                  required: "전화번호 필수 입력 값 입니다.",
                  pattern: CustomRegExp("tel"),
                })}
                errors={formErrors}
                iMaxlength={12}
              />

              <InputUnit
                holder="이메일을 입력해주세요"
                register={register("customer_email", {
                  pattern: CustomRegExp("email"),
                })}
                errors={formErrors}
                iMaxlength={100}
              />

              <div className="input-unit">
                <textarea
                  id="content"
                  textAlignVertical="top"
                  multiline={true}
                  placeholder="내용"
                  value={textValue}
                  onChange={(e) => handleSetValue(e)}
                  onKeyDown={(e) => handleSetTab(e)}
                />
              </div>

              <ul className="board-btn-area">
                <li>
                  <button
                    className="write-btn"
                    type="submit"
                    onClick={handleSubmit(sendEmail)}
                  >
                    등록
                  </button>
                </li>
                <li>
                  <button
                    className="cancel-btn"
                    type="button"
                    onClick={onMainHandler}
                  >
                    취소
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reservation;
