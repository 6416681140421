import React, { useEffect } from "react";
import ContentNav from "components/common/contentNav";
import KakaoMap from "api/kakaoMap";
import "assets/css/injunglaw/direction.css";

function Direction() {
  return (
    <>
      <ContentNav />
      <div className="container">
        <div className="content-box">
          <div className="direction .content .txArea .tb1">
            <div className="content">
              <div className="txArea">
                <div className="tb1">
                  <span>기업법무의 편안한 동반자</span>
                </div>
                <div className="tb2">
                  <span>INJUNG</span>
                </div>
                <div className="tb3">
                  <span>인정합동법무사</span>
                </div>
              </div>
              <div className="txAreaBt">
                <div className="tb4">
                  <span>Add.</span>
                </div>
                <div className="tb5">
                  <span>
                    서울특별시 강남구 테헤란로7길 7, 12층 (역삼동, 에스코빌딩)
                  </span>
                </div>
              </div>
              <div className="txAreaBt2">
                <div className="cl">
                  <div className="tb6">
                    <span>Tel.</span>
                  </div>
                  <div className="tb7">
                    <span>02.566.5885</span>
                  </div>
                </div>
                <div className="cl">
                  <div className="tb6">
                    <span>Fax.</span>
                  </div>
                  <div className="tb7">
                    <span>02.566.5886</span>
                  </div>
                </div>
                <div className="cl">
                  <div className="tb6">
                    <span>E-mail.</span>
                  </div>
                  <div className="tb7">
                    <span>khy6378@naver.com(김희영법무사)</span>
                  </div>
                </div>
              </div>
              <KakaoMap />
              <div className="info">
                <div className="subway">
                  <div className="content">
                    <img src="/assets/images/icons/subway.png" />
                    <div className="title">
                      <span>지하철</span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="rt1">
                      <span>2 강남역</span>
                    </div>
                    <div className="rt2">
                      <span>신분당 강남역</span>
                    </div>
                    <div className="tb8">
                      <span>강남역 12번출구에서 도보 4분 소요</span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="rt1">
                      <span>2 역삼역</span>
                    </div>
                    <div className="rt4" />
                    <div className="tb8">
                      <span>역삼역 4번출구에서 도보 8분 소요</span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="rt3">
                      <span>9 신논현</span>
                    </div>
                    <div className="rt2">
                      <span>신분당 신논현</span>
                    </div>
                    <div className="tb8">
                      <span>신논현역 6번출구에서 도보 12분 소요</span>
                    </div>
                  </div>
                </div>
                <div className="bus">
                  <div className="content">
                    <img src="/assets/images/icons/bus.png" />
                    <div className="title">
                      <span>버스</span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="desc">
                      <span>
                        강남역12번출구, 강남역, 강남역. 역삼세무서,
                        역삼역.포스코타워역삼
                      </span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="tb10">
                      <span>간선(파랑)</span>
                    </div>
                    <div className="tb14">
                      <span>146, 341, 360, 740</span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="tb11">
                      <span>지선(초록)</span>
                    </div>
                    <div className="tb14">
                      <span>3412</span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="tb12">
                      <span>광역(빨강)</span>
                    </div>
                    <div className="tb14">
                      <span>
                        1100, 1700, 2000 , 2000-1, 3200, 3300, 3600, 7007
                      </span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="tb13">
                      <span>공항버스</span>
                    </div>
                    <div className="tb14">
                      <span>6020</span>
                    </div>
                  </div>
                </div>
                <div className="car">
                  <div className="content">
                    <img src="/assets/images/icons/car.png" />
                    <div className="title">
                      <span>자동차</span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="tb15">
                      <span>
                        역삼역에서 강남역 진행 방향 테해란로7길 7 우회전 진입 후
                        30m 직진
                      </span>
                    </div>
                  </div>
                  <div className="content">
                    <div className="tb15">
                      <span>1층 CU옆 주차장 이용가능</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Direction;
