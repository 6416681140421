import { useEffect, useRef } from "react";
const { kakao } = window;

const KakaoMap = () => {
  const container = useRef(null);
  const options = {
    center: new kakao.maps.LatLng(37.4996, 127.0305),
    level: 3,
  };

  useEffect(() => {
    const map = new kakao.maps.Map(container.current, options);
    var markerPosition = new kakao.maps.LatLng(37.4996, 127.0305);
    //마커생성
    var marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    marker.setMap(map);
  }, []);

  return (
    <div
      id={"map"}
      style={{
        width: "100%",
        height: "627px",
      }}
      ref={container}
      level={3}
    ></div>
  );
};

export default KakaoMap;
