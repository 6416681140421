import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";
import "../../assets/css/common/footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const handleLogOut = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      userInfo: null,
    });
    localStorage.removeItem("rToken");
    navigate("/");
  };

  const LoginBtn = () => {
    if (userInfo.isLogin)
      return (
        <li>
          <Link to="/" onClick={handleLogOut}>
            LOG OUT
          </Link>
        </li>
      );
    else
      return (
        <li>
          <Link to="/login">ADMIN</Link>
        </li>
      );
  };

  return (
    <div className="container bg-black">
      <div className="content-box">
        <footer className="footer">
          <div className="f-left-section">
            <div className="f-left-top">
              <p className="footer-cont">인정법무사는</p>
              <p className="footer-cont">새롭게 나아갑니다.</p>
              <p className="footer-cont-mobile">
                인정법무사는 새롭게 나아갑니다.
              </p>
            </div>
            <div className="f-left-bottom">
              <img src="/assets/images/common/footer_logo.png" />
            </div>
          </div>
          <div className="f-right-section">
            <div className="f-nav">
              <ul>
                <li>
                  <Link to="/injung/intro">인정법무사</Link>
                </li>
                <li>
                  <Link to="/business/category?id=1&sub=0">업무내용</Link>
                </li>
                <li>
                  <Link to="/support/question">고객지원</Link>
                </li>
                <li>
                  <Link to="/trend/case">최신경향</Link>
                </li>
                <LoginBtn />
              </ul>
            </div>
            <br />
            <div className="f-info">
              상호 : 인정합동법무사  / 대표 : 김희영  / 사업자번호 :
              220-08-82505
              <br />
              주소 : 서울특별시 강남구 테헤란로7길 7. 12층(역삼동,에스코빌딩)
              <br />
              TEL : 02 - 566 - 5885 / FAX : 02 - 566 - 5886
              <br />
              E-mail : khy6378@naver.com
            </div>
            <div className="f-info-mobile">
              상호 : 인정합동법무사 / 대표 : 김희영 / 사업자번호 : 220-08-82505
              <br />
              주소 : 서울특별시 강남구 테헤란로7길 7. 12층(역삼동,에스코빌딩)
              <br />
              TEL : 02 - 566 - 5885 / FAX : 02 - 566 - 5886
              <br />
              E-mail : khy6378@naver.com
            </div>
            <br />
            <div className="f-copy">
              COPYRIGHT (C) INJUNG. ALL RIGHT RESERVED
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
