import React from "react";
import { cls } from "../helpers/helper";

const InputUnit = ({
  label,
  type = "text",
  holder,
  register,
  errors,
  clsName,
  noLable,
  iMaxlength,
  ...rest
}) => {
  const id = register.name;
  let placeholder;
  holder
    ? (placeholder = holder)
    : (placeholder = `${id.toString().toUpperCase()}를 입력해 주세요`);
  return (
    <div className="input-unit">
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        className={cls("input-hook", clsName, errors[id] && "error")}
        {...register}
        {...rest}
        maxLength={iMaxlength}
      />
      {errors[id] && (
        <span className="error-message">{errors[id].message}</span>
      )}
    </div>
  );
};

export default InputUnit;
