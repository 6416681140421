import React from "react";
import { Link } from "react-router-dom";

const goTop = (e) => {
  e.preventDefault();
  window.scrollTo(0, 0);
};

const FloatingBtn = () => {
  return (
    <>
      <div className="floating">
        <div>
          <Link to="/support/reference">
            <img src="/assets/images/icons/ref_link.png" />
          </Link>
        </div>
        <div>
          <img src="/assets/images/icons/top.png" onClick={goTop} />
        </div>
        <div className="tel">
          <img src="/assets/images/icons/telephone.png" />
          <div className="tx-area">
            <div className="tx1">
              <span>전화문의</span>
            </div>
            <div className="tx2">
              <span>02 - 566 - 5885</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloatingBtn;
