import React from "react";
import ContentNav from "components/common/contentNav";
import "assets/css/injunglaw/intro.css";
import "assets/css/common/main.css";

function Intro() {
  return (
    <>
      <ContentNav />
      <div className="container">
        <div className="content-box">
          <div className="intro">
            <div className="bg">
              <img src="/assets/images/injunglaw/intro01.png" />
            </div>
            <div className="bg-m">
              <img src="/assets/images/injunglaw/intro02.png" />
            </div>
            <div className="tb1">
              <span>
                기업법무의 정도를 지향하는 사무소로서 20여년간 수행한 다양한
                업무경험을 바탕으로 법인설립에서 상장까지
                <br />
                회사업무의 질 높은 서비스를 제공하며 귀사의 친절하고 편안한 법무
                동반자가 되어 드리겠습니다.
              </span>
            </div>
            <div className="tb1-m">
              <span>
                기업법무의 정도를 지향하는 사무소로서
                <br />
                20여년간 수행한 다양한 업무경험을 바탕으로
                <br />
                법인설립에서 상장까지 회사업무의 질 높은
                <br />
                서비스를 제공하며 귀사의 친절하고 편안한
                <br />
                법무 동반자가 되어 드리겠습니다.
              </span>
            </div>
            <div className="tb2">
              <span>Introduction</span>
            </div>
            <div className="tb3">
              <span>법무사 소개</span>
            </div>
            <div className="ozArea">
              <ul className="ozList">
                <li>
                  <div className="content">
                    <div className="el-6">
                      <img src="/assets/images/injunglaw/pic1.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 김희영</span>
                    </div>
                    <div className="tb5">
                      <span>대표 법무사</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>070.4808.1928</span>
                      </div>
                      <div>
                        <img src="/assets/images/icons/phone.png" />
                        <span>010.2356.6378</span>
                      </div>
                      <div>
                        <img src="/assets/images/icons/email.png" />
                        <span>khy6378@naver.com</span>
                      </div>
                    </div>
                    <div className="rt" />
                    <div className="bt">
                      <div className="title">
                        <span>Profile</span>
                      </div>
                      <div className="pf">
                        <span>
                          2011&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;인정합동법무사대표
                        </span>
                      </div>
                      <div className="pf">
                        <span>
                          2004&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나무합동법무사
                        </span>
                      </div>
                      <div className="pf">
                        <span>
                          2003&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;제9회
                          법무사시험
                        </span>
                      </div>
                      <div className="pf">
                        <span>
                          1997~2000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LG화재
                          기획팀 근무
                        </span>
                      </div>
                      <div className="pf">
                        <span>
                          1997&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;서울시립대학교법학과졸업
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <div className="el-6">
                      <img src="/assets/images/injunglaw/pic2.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 노형후</span>
                    </div>
                    <div className="tb5">
                      <span>법무사</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>070.4808.1925</span>
                      </div>
                    </div>
                    <div className="rt" />
                    <div className="bt">
                      <div className="title">
                        <span>Profile</span>
                      </div>
                      <div className="pf">
                        <span>
                          2018.05&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;인정합동법무사
                        </span>
                      </div>
                      <div className="pf">
                        <span>
                          2006.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;제22회
                          법무사시험 합격
                        </span>
                      </div>
                      <div className="pf">
                        <span>
                          2009.02&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;성신여대
                          대학원 졸업
                        </span>
                      </div>
                      <div className="pf">
                        <span>
                          2007.02&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;성신여대
                          법학과 수석 졸업
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="rt-33"></div>
            <div className="ozArea">
              <ul className="ozList">
                <li>
                  <div className="content">
                    <div className="el-7">
                      <img src="/assets/images/injunglaw/pic3.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 신정원</span>
                    </div>
                    <div className="tb5">
                      <span>실장</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>02.566.5885</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <div className="el-6">
                      <img src="/assets/images/injunglaw/pic4.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 이용재</span>
                    </div>
                    <div className="tb5">
                      <span>과장</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>070.4808.1924</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <div className="el-6">
                      <img src="/assets/images/injunglaw/pic5.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 이범열</span>
                    </div>
                    <div className="tb5">
                      <span>대리</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>070.4808.1926</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <div className="el-6">
                      <img src="/assets/images/injunglaw/pic6.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 이실</span>
                    </div>
                    <div className="tb5">
                      <span>대리</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>070.4808.1923</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <div className="el-6">
                      <img src="/assets/images/injunglaw/pic7.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 김수환</span>
                    </div>
                    <div className="tb5">
                      <span>사원</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>070.5214.2452</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <div className="el-6">
                      <img src="/assets/images/injunglaw/pic8.jpg" />
                    </div>
                    <div className="tb4">
                      <span>인정법무사 한송이</span>
                    </div>
                    <div className="tb5">
                      <span>사원</span>
                    </div>
                    <div className="telArea">
                      <div>
                        <img src="/assets/images/icons/tel.png" />
                        <span>070.5214.2451</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;
