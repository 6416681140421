import React from "react";
import ContentNav from "components/common/contentNav";
import "assets/css/injunglaw/greetings.css";
import "assets/css/common/main.css";

function Greetings() {
  return (
    <>
      <ContentNav />
      <div className="bgImg">
        <img src="/assets/images/injunglaw/greeting01.png" />
      </div>
      <div className="container">
        <div className="content-box">
          <div className="greetings">
            <div className="content">
              <div className="txArea">
                <div className="tb1">
                  <span>Trust & faith</span>
                </div>
                <div className="tb2">
                  <span>
                    기업의 든든한 법무 파트너,
                    <br /> 믿고 신뢰할 수 있는 합동법무사
                  </span>
                </div>
              </div>
              <div className="txAreaBt">
                <div className="tb1">
                  <span>
                    20년 이상 상사회사 및 기업법무를 수행하면서 쌓은
                    <br />
                    다양한 노하우를 고객 여러분과 최선을 다해 공유하고 있습니다.
                  </span>
                </div>
                <div className="tb2">
                  <span>
                    특히 현물출자, 법인합병, 분할, 외국인투자, 전환사채 및
                    종류주식을 통한
                    <br />
                    신주발행 등 특화된 경험치를 바탕으로 귀사에 최적화된 업무를
                    지원하고 있습니다.
                    <br />
                    <br />
                    귀사의 든든한 법무 해결사가 되어 드리겠습니다.
                  </span>
                </div>
                <div className="tb1-m">
                  <span>
                    20년 이상 상사회사 및 기업법무를
                    <br />
                    수행하면서 쌓은 다양한 노하우를 고객
                    <br />
                    여러분과 최선을 다해 공유하고 있습니다.
                  </span>
                </div>
                <div className="tb2-m">
                  <span>
                    특히 현물출자, 법인합병, 분할, 외국인투자, 전환사채 및
                    종류주식을
                    <br />
                    통한 신주발행 등 특화된 경험치를 바탕으로 귀사에 최적화된
                    <br />
                    업무를 지원하고 있습니다.
                    <br />
                    <br />
                    귀사의 든든한 법무 해결사가 되어 드리겠습니다.
                  </span>
                </div>
                <div className="tb3">
                  <span>
                    INJUNG
                    <br />
                    Legal Services
                  </span>
                </div>
                <div className="bg">
                  <img src="/assets/images/injunglaw/greeting02.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Greetings;
