import React from "react";
import "assets/css/common/main.css";

function Background() {
  return (
    <div>
      <div className="mainLayer">
        <img src="assets/images/main/layer.png" />
      </div>
    </div>
  );
}

export default Background;
