import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import router from "./route/router";
import Header from "components/common/header";
import Footer from "components/common/footer";
import FloatingBtn from "components/common/floatingBtn";
import ScrollToTop from "api/scrollToTop";
import "assets/css/reset.css";

const Routing = () => {
  let routes = useRoutes(router);
  return routes;
};

const App = () => {
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Header />
        <Routing />
        <FloatingBtn />
        <Footer />
      </Router>
    </>
  );
};

export default App;
