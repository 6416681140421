import React from "react";
import "../../assets/css/main/youtube.css";

const YouTube = () => {
  return (
    <div className="container bg-youtube">
      <div className="content-box">
        <div className="youtube">
          <div className="youtube_bg_m">
            <img src="assets/images/main/youtube/youtube_bg_m.png" />
          </div>
          <p className="youtube-main-title">
            인정은 지금도 고객님들과 함께 성장하고 있습니다.
          </p>
          <img
            className="youtube-logo"
            src="assets/images/common/youtube_logo.png"
          />
          <div className="youtube-video">
            <iframe
              className="youtube-thumbnail"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/igSYvMPmcRQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="youtube-info">
              <p className="youtube-date">2021.07.27</p>
              <p className="youtube-title">
                김희영 법무사의 법인설립 원포인트 특강
              </p>
              <p className="youtube-intro">
                많은 분들께서 김희영 법무사님 컨텐츠들을 좋아해주셔서 김희영
                법무사님 단독으로 별도 편성한 시간입니다. 법인 설립 시 체크가
                필요한 사항들 빠짐없이 체크해보세요.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouTube;
