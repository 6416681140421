import React from "react";
import Visual from "pages/main/visual";
import MainBoard from "pages/main/mainBoard";
import Business from "pages/main/business";
import ReferQuestion from "pages/main/referQuestion";
import MainQuickMenu from "pages/main/mainQuickMenu";
import Youtube from "pages/main/youtube";
import MainIntro from "pages/main/mainIntro";
import Background from "pages/main/background";
import "assets/css/common/main.css";

function Home() {
  return (
    <div>
      <Background />
      <Visual />
      <MainBoard />
      <Business />
      <MainIntro />
      <Youtube />
      <MainQuickMenu />
      <ReferQuestion />
    </div>
  );
}

export default Home;
