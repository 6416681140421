import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { naviMenus } from "data/businessMenu";
import "assets/css/common/nav.css";

const Header = () => {
  const history = useLocation();
  const [mobileNavStyle, setMobileNavStyle] = useState({});
  const [mobileNavListStyle, setMobileNavListStyle] = useState({});
  const [mouseOverState, setMouseOverState] = useState(false);
  const [menuArray, setMenuArray] = useState(naviMenus);

  /* 메뉴 보이기 */
  const showMenu = () => {
    setMouseOverState(true);
  };

  /* 메뉴 숨기기 */
  const hideMenu = () => {
    setMouseOverState(false);
  };

  /* 모바일 메뉴 보이기 */
  const showMobileNav = () => {
    const style = {
      display: "block",
    };
    setMobileNavStyle(style);

    const listStyle = {
      width: "80%",
    };
    setMobileNavListStyle(listStyle);
  };

  /* 모바일 메뉴 숨기기 */
  const hideMobileNav = () => {
    const style = {
      display: "none",
    };
    setMobileNavStyle(style);

    const listStyle = {
      width: "0%",
    };
    setMobileNavListStyle(listStyle);
  };

  /* 모바일 내에 서브메뉴펼치기 */
  const spreadSubList = (itemLocate) => {
    let copyMenuArray = menuArray.map((item) => {
      if (itemLocate === item.mainLocate) {
        return {
          mainTitle: item.mainTitle,
          mainLocate: item.mainLocate,
          subMenu: item.subMenu,
          mobileShowStatus: true,
        };
      } else {
        return {
          mainTitle: item.mainTitle,
          mainLocate: item.mainLocate,
          subMenu: item.subMenu,
          mobileShowStatus: false,
        };
      }
    });

    setMenuArray(copyMenuArray);
  };

  const showSubList = (itemLocate) => {
    menuArray.forEach((item) => {
      if (item.mainLocate == itemLocate) {
        if (item.mobileShowStatus) {
          spreadSubList("allhide");
        } else {
          spreadSubList(itemLocate);
        }
      }
    });
  };

  useEffect(() => {
    hideMobileNav();
  }, [history]);

  return (
    <div>
      <nav className="nav" onMouseOver={showMenu}>
        <div className="logo">
          <Link to={"/"}>
            <img src="/assets/images/common/logo.png" />
          </Link>
        </div>
        <div className="nav-menu">
          <ul className="nav-main-ul">
            {menuArray.map((item, index) => (
              <li key={item.mainLocate}>
                {/* <Link className={item.focusState}>{item.mainTitle}</Link> */}
                <span className={item.focusState}>{item.mainTitle}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="hbg">
          <button onClick={showMobileNav} className="hbg_btn">
            <img src="/assets/images/common/allmenuIco.png" />
          </button>
        </div>
      </nav>
      {mouseOverState && (
        <div className="sub-nav" onMouseLeave={hideMenu}>
          <div className="sub-menu">
            <ul className="sub-main-ul">
              {menuArray.map((item, index) => (
                <li key={index}>
                  <ul>
                    {item.subMenu.map((subItem, subIndex) => (
                      <li key={subItem.label}>
                        <Link to={`/${subItem.subLocate}`}>
                          {subItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div
        className="mobile-nav-bg"
        onClick={hideMobileNav}
        style={mobileNavStyle}
      ></div>
      <div className="mobile-nav-menu" style={mobileNavListStyle}>
        <ul>
          <li onClick={hideMobileNav}>
            <img src="/assets/images/common/close.png" />
          </li>
          {menuArray.map((item, index) => (
            <li key={item.mainLocate}>
              <div
                className="sub-list-title"
                onClick={() => {
                  showSubList(item.mainLocate);
                }}
              >
                <p style={item.mobileShowStatus ? { color: "#2c7eea" } : null}>
                  {item.mainTitle}
                </p>
                {!item.mobileShowStatus ? (
                  <p
                    onClick={() => {
                      spreadSubList(item.mainLocate);
                    }}
                    className="spreadBtn"
                  >
                    +
                  </p>
                ) : (
                  <p
                    style={item.mobileShowStatus ? { color: "#2c7eea" } : null}
                    onClick={() => {
                      spreadSubList("allHide");
                    }}
                    className="spreadBtn"
                  >
                    -
                  </p>
                )}
              </div>
              {item.mobileShowStatus && (
                <ul className="mobile-sub-list">
                  {item.subMenu.map((subItem, subIndex) => (
                    <li key={subItem.subLocate}>
                      <Link to={`${subItem.subLocate}`}>{subItem.label}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
