const LimitStr = (title, limit = 17) => {
  let newTitle = "";
  if (title != null) {
    if (title.length > limit) {
      newTitle = title.substr(0, limit - 2) + "...";
      return newTitle;
    } else {
      return title;
    }
  } else {
    return null;
  }
};

export { LimitStr };
