	const CustomRegExp =  (value) => {
		let reg = {};
		if (value == "name") {
			reg = {
				value: /^[가-힣a-zA-Z()]{2,20}$/,
				message: "올바르지 않은 이름입니다.",
			};
		} else if (value == "tel") {
			reg = {
				value: /^(01[016789]{1}|070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/,
				message: "올바르지 않은 전화번호입니다. '-' 없이 입력해주세요.",
			};
		} else if (value == "email") {
			reg = {
				value: /.+@.+..+/,
				message: "올바르지 않은 이메일입니다.",
			};
		} else if (value == "password") {
			reg = {
				value: 4,
				message: "패스워드는 최소 4자리 이상으로 입력해야합니다.",
			};
		}
		return reg;
  };


	export { CustomRegExp }

