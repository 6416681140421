import React, { useEffect, useRef, useState, useMemo } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MenuIcon, EditIcon, TrashIconB } from "assets/images/icons/icons";
import useAxiosFunction from "hooks/useAxios";
import { userInfoState } from "recoil/userStore";
import moment from "moment";
import ReactQuill from "react-quill";
import QuestionBoardPw from "components/board/questionBoardPw";
import { checkPwState } from "recoil/boardStore";

const QuestionDetail = () => {
  const [quillValue, setQuillValue] = useState("");
  const userInfo = useRecoilValue(userInfoState);
  const [checkSuccess, setCheckSuccess] = useRecoilState(checkPwState);
  const [questionData, setQuestionData] = useState();
  const [replyData, setReplyData] = useState();
  const navigate = useNavigate();
  const { axiosFetch, apiUrls, axiosFetchDown } = useAxiosFunction();
  const { postId } = useParams();
  const quillRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const modules = useMemo(() => {
    return {
      toolbar: null,
    };
  }, []);

  const onWriteModeHandler = () => {
    navigate("/support/question");
  };

  const onDetailArticle = (e) => {
    e.preventDefault();
    navigate("/support/question");
  };

  const onEditHandler = (e) => {
    e.preventDefault();
    navigate(`/support/question/edit/${postId}`);
  };

  const onDeleteHandler = (e) => {
    e.preventDefault();

    axiosFetch({
      method: "post",
      url: apiUrls.deleteQuestion,
      requestConfig: { postId: postId },
    })
      .then((res) => {
        if (res.success) {
          navigate("/support/question");
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const questionDownload = (e) => {
    e.preventDefault();
    console.log(apiUrls.questionDownload + `/${questionData?.attached_file}`);
    axiosFetchDown({
      method: "get",
      url: apiUrls.questionDownload + `/${questionData?.attached_file}`,
      requestConfig: {},
    })
      .then((res) => {
        const href = URL.createObjectURL(res);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", questionData?.origin_file); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const questionDetail = () => {
    axiosFetch({
      method: "get",
      url: apiUrls.getQuestionDetail(postId),
    })
      .then((res) => {
        if (res.success) {
          setQuestionData(res.result);
          setReplyData(res.reply);
        }
      })
      .catch((err) => {
        console.log("[ERROR] get question detail : ", err);
      });
  };

  const writeComment = () => {
    if (quillValue === "") {
      alert("내용을 입력해주세요.");
      return;
    }

    axiosFetch({
      method: "post",
      url: apiUrls.writeQuestionComment,
      requestConfig: {
        name:
          userInfo.isLogin === true ? "인정법무사" : questionData.customer_name,
        board_id: questionData.board_id,
        comment_content: quillValue.replace(/"/g, '\\"'),
      },
    })
      .then((res) => {
        if (res.success) {
          var element = document.getElementsByClassName("ql-editor");
          element[0].innerHTML = "";
          setQuillValue("");
          questionDetail();
        }
      })
      .catch((err) => {});
  };

  const delComment = (e, reqData) => {
    e.preventDefault();

    axiosFetch({
      method: "post",
      url: apiUrls.deleteQuestionComment,
      requestConfig: {
        comment_id: reqData,
      },
    })
      .then((res) => {
        if (res.success) {
          questionDetail();
        }
      })
      .catch((err) => {});
  };

  function createMarkup() {
    return { __html: questionData?.content };
  }

  function createCommentMarkup(item) {
    return { __html: item };
  }

  useEffect(() => {
    questionDetail();
  }, []);

  useEffect(() => {
    return () => {
      setCheckSuccess(false);
    };
  }, []);

  return (
    <>
      {checkSuccess || userInfo.isLogin ? (
        <div className="detail-box">
          <div className="detail-title-box">
            <div className="detail-writer">{questionData?.title}</div>
            <div>
              <ul className="detail-writer">
                <li>작성자 : {questionData?.customer_name}</li>
                <li>{moment(questionData?.board_date).format("YYYY/MM/DD")}</li>
                <li>조회수: {questionData?.read_count}</li>
              </ul>
            </div>
          </div>
          <div className="detail-content-box">
            <div
              className="board-content"
              dangerouslySetInnerHTML={createMarkup()}
            ></div>
            <div className="board-file">
              {questionData?.origin_file != null && (
                <Link onClick={questionDownload} href="#">
                  <div className="board-comment">
                    <div className="rt2" />
                    <div className="title">
                      <span>첨부파일</span>
                    </div>
                    <div className="rt1" />
                    <div className="file">
                      <img src="/assets/images/common/download.png" />
                      <span>{questionData?.origin_file}</span>
                    </div>
                  </div>
                </Link>
              )}
            </div>
            <div className="board-comment">
              <div className="rt2" />
              <div className="title">
                <span>답변</span>
              </div>
              <div className="rt1" />
              {replyData?.map((item) => (
                <li key={item.comment_id}>
                  <div className="top">
                    <div className="name">
                      <span>{item.name}</span>
                      <span>|</span>
                      <span>
                        {moment(item.comment_date).format("YYYY/MM/DD")}
                      </span>
                    </div>
                    {userInfo.isLogin ? (
                      <div className="func">
                        <button onClick={(e) => delComment(e, item.comment_id)}>
                          <span>삭제</span>
                        </button>
                      </div>
                    ) : !userInfo.isLogin && item.name !== "인정법무사" ? (
                      <div className="func">
                        <button onClick={(e) => delComment(e, item.comment_id)}>
                          <span>삭제</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="comment-write"
                    dangerouslySetInnerHTML={createCommentMarkup(
                      item.comment_content
                    )}
                  ></div>
                  <div className="rt1" />
                </li>
              ))}
              <div className="name">
                <span>
                  {userInfo.isLogin === true
                    ? "인정법무사"
                    : questionData.customer_name}
                </span>
                <span>|</span>
                <span>{moment().format("YYYY/MM/DD")}</span>
              </div>
              <div className="comment-write">
                <form className="input-area">
                  <ReactQuill
                    className="editor"
                    placeholder="내용을 입력해주세요."
                    theme="snow"
                    modules={modules}
                    onChange={setQuillValue}
                    required="내용은 필수 입력 값 입니다."
                    ref={quillRef}
                  />
                </form>

                <div className="comment-btn-area">
                  <button
                    type="submit"
                    className="write-btn"
                    onClick={handleSubmit(writeComment)}
                  >
                    답변하기
                  </button>
                </div>
              </div>
              <div className="rt2" />
            </div>
            <div className="board-btn">
              <div className="btn-box">
                <button onClick={onEditHandler}>
                  <EditIcon className="btn-ico" />
                  수정
                </button>
                <button onClick={onDeleteHandler}>
                  <TrashIconB className="btn-ico" />
                  삭제
                </button>
                <button onClick={onDetailArticle}>
                  <MenuIcon className="btn-ico" />
                  목록
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <QuestionBoardPw postId={postId} />
      )}
    </>
  );
};

export default QuestionDetail;
