import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";

function TrendList() {
  const userInfo = useRecoilValue(userInfoState);
  const [trendResult, setTrendResult] = useState([]);
  const [trendList, setTrendList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const getTrendInfo = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.getTrendList,
      requestConfig: { currentNavPage: reqData },
    })
      .then((res) => {
        if (res.success) {
          setTrendResult(res.result);
          setTrendList(res.trendList);
          setPageList(res.pageList);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    getTrendInfo(null);
  }, []);

  return (
    <>
      <div className="board-list">
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {trendResult.totalrows}
            <span>건의 게시물</span>
          </div>
          <div className="board-write-btn">
            {userInfo.userInfo && (
              <Link to={`/trend/case/write`}>
                <button className="write-btn">작성하기</button>
              </Link>
            )}
          </div>
        </div>
        <ul className="boardList">
          <li className="head">
            <div className="board-num-title">
              <strong>번호</strong>
            </div>
            <div className="board-subj">
              <strong>제목</strong>
            </div>
            <div className="board-writer">
              <strong>작성자</strong>
            </div>
            <div className="board-date">
              <strong>작성일</strong>
            </div>
            <div className="board-count">
              <strong>조회수</strong>
            </div>
          </li>
          {trendList?.map((item, index) => (
            <>
              <li key={item.title} className="mobile-board-li">
                <Link to={`/trend/case/detail/${item.board_id}`}>
                  <p className="mobile-board-title">{item.title}</p>
                  <div className="mobile-board-sub-box">
                    <p className="mobile-board-writer">관리자</p>
                    <div className="mobile-board-flex">
                      <img src="/assets/images/icons/board_time.png" />
                      <p className="mobile-board-date">
                        {moment(item.board_date).format("YYYY/MM/DD")}
                      </p>
                    </div>
                    <div className="mobile-board-flex">
                      <img src="/assets/images/icons/board_eye.png" />
                      <p className="mobile-board-rcount">{item.read_count}</p>
                    </div>
                  </div>
                </Link>
              </li>
              <li key={index} className="pc-board-li">
                <Link to={`/trend/case/detail/${item.board_id}`}>
                  <div className="board-num">{item.board_id}</div>
                  <div className="board-subj">
                    <div className="writer">{item.title}</div>
                    {item.isNew ? (
                      <div className="new">
                        <img src="/assets/images/sub/newIco.png" />
                      </div>
                    ) : null}
                  </div>
                  <div className="board-writer">관리자</div>
                  <div className="board-date">
                    {moment(item.board_date).format("YYYY/MM/DD")}
                  </div>
                  <div className="board-count">{item.read_count}</div>
                </Link>
              </li>
            </>
          ))}
        </ul>
      </div>

      <ul className="paging">
        <li>
          <Link
            onClick={() =>
              getTrendInfo(
                trendResult.currentPage - 10 < 1
                  ? 1
                  : trendResult.currentPage - 10
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/icons/boardFirst.png" />{" "}
          </Link>
        </li>
        {pageList?.map((item, index) => (
          <li
            key={index}
            className={item === trendResult.currentPage ? "on" : null}
          >
            <Link
              onClick={() => getTrendInfo(item)}
              style={{ cursor: "pointer" }}
            >
              {item}
            </Link>
          </li>
        ))}

        <li>
          <Link
            onClick={() =>
              getTrendInfo(
                pageList[pageList.length - 1] + 1 < trendResult.maxPage
                  ? pageList[pageList.length - 1] + 1
                  : pageList[pageList.length - 1]
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/icons/boardLast.png" />
          </Link>
        </li>
      </ul>
    </>
  );
}

export default TrendList;
