import React, { useEffect, useState } from "react";
import useAxiosFunction from "hooks/useAxios";
import { LimitStr } from "helpers/limitStr";
import { Link } from "react-router-dom";
import "../../assets/css/main/referQuestion.css";

const ReferQuestion = () => {
  const [referIndex, setReferIndex] = useState(0);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [referenceListMain, setReferenceListMain] = useState([]);
  const [referenceThreeList, setReferenceThreeList] = useState([]);

  const changeReferenceList = (index) => {
    let copyReferenceListMain = [];
    for (var i = index; i < index + 3; i++) {
      copyReferenceListMain.push(referenceListMain[i]);
    }
    setReferenceThreeList(copyReferenceListMain);
  };

  const getReferenceInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getReferenceListMain,
      requestConfig: {},
    })
      .then((res) => {
        if (res.success) {
          setReferenceListMain(res.referenceMainList);
          let copyReferenceListMain = [];
          for (var i = 0; i < 3; i++) {
            copyReferenceListMain.push(res.referenceMainList[i]);
          }
          setReferenceThreeList(copyReferenceListMain);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const prevReferMain = (e) => {
    e.preventDefault();
    if (referIndex > 0) {
      changeReferenceList(referIndex - 3);
      setReferIndex(referIndex - 3);
    }
  };

  const nextReferMain = (e) => {
    e.preventDefault();
    if (referIndex < 27) {
      changeReferenceList(referIndex + 3);
      setReferIndex(referIndex + 3);
    }
  };

  useEffect(() => {
    getReferenceInfo();
  }, []);

  return (
    <div className="container">
      <div className="content-box">
        <div className="main-section-six">
          <div className="six-left-section">
            <div className="secsix-left">
              <div className="main-refer">
                <div className="secsix-left-title">자료실</div>
                <div className="secsix-left-arrow">
                  <div className="arrow-title">
                    <Link to={"/support/reference"}>자료실 내용 전체보기</Link>
                  </div>
                  <a onClick={prevReferMain}>
                    <img src="assets/images/common/arrow_left_s.png" />
                  </a>
                  <div className="pillar"></div>
                  <a onClick={nextReferMain}>
                    <img src="assets/images/common/arrow_right_s.png" />
                  </a>
                </div>
              </div>
              {referenceThreeList?.map((item, index) => (
                <Link
                  to={`/support/reference/detail/${item?.board_id}`}
                  key={index}
                >
                  <div className="main-refer-list">
                    <div className="main-date-box">
                      <div className="main-refer-date">{item?.f_date}</div>
                      <div className="main-refer-date-two">{item?.f_year}</div>
                    </div>
                    <div className="pillar"></div>
                    <div className="main-refer-list-title">
                      {LimitStr(item?.title, 33)}
                    </div>
                    <div className="main-refer-list-title-mobile">
                      {LimitStr(item?.title, 22)}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="six-right-section">
            <div className="secsix-right">
              <Link to={"/support/reservation"}>
                <div className="main-question-list list-underline">
                  <div className="six-right-content">
                    <p className="r-cont-title">상담 예약</p>
                    <p className="r-cont-detail">
                      상담을 통해 궁금한것을 인정에게 물어보세요!
                    </p>
                  </div>
                  <div className="six-right-icon">
                    <img src="assets/images/common/main_pen.png" />
                  </div>
                </div>
              </Link>
              <a href="tel:02-566-5885">
                <div className="main-question-list">
                  <div className="six-right-content">
                    <p className="r-cont-title">실시간 전화문의</p>
                    <p className="r-cont-detail">
                      언제나 필요하시면 연락주세요! 친절히 상담해드리겠습니다.
                    </p>
                  </div>
                  <div className="six-right-icon">
                    <img src="assets/images/common/main_tel.png" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferQuestion;
