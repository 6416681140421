import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { MenuIcon, EditIcon, TrashIconB } from "assets/images/icons/icons";
import useAxiosFunction from "hooks/useAxios";
import { userInfoState } from "recoil/userStore";
import moment from "moment";

const ReferenceDetail = () => {
  const userInfo = useRecoilValue(userInfoState);
  const navigate = useNavigate();
  const [referenceData, setReferenceData] = useState([]);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const { postId } = useParams();

  const onDetailArticle = (e) => {
    e.preventDefault();
    navigate("/support/reference");
  };

  const onEditHandler = (e) => {
    e.preventDefault();
    navigate(`/support/reference/edit/${postId}`);
  };

  const onDeleteHandler = (e) => {
    e.preventDefault();

    axiosFetch({
      method: "post",
      url: apiUrls.deleteReference,
      requestConfig: { postId: postId },
    })
      .then((res) => {
        if (res.success) {
          navigate("/reference");
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    axiosFetch({
      method: "get",
      url: apiUrls.getReferenceDetail(postId),
      requestConfig: null,
    })
      .then((res) => {
        if (res.success) {
          setReferenceData(res.result[0]);
        }
      })
      .catch((err) => {
        console.log("[ERROR] get reference info : ", err);
      });
  }, []);

  function createMarkup() {
    return { __html: referenceData.content };
  }

  return (
    <div className="detail-box">
      <div className="detail-title-box">
        <div className="detail-writer">{referenceData.title}</div>
        <div>
          <ul className="detail-writer">
            <li>
              작성자 :{" "}
              {referenceData?.customer_id === "admin"
                ? "관리자"
                : referenceData?.customer_id}
            </li>
            <li>{moment(referenceData.date).format("YYYY/MM/DD")}</li>
            <li>조회수: {referenceData?.read_count}</li>
          </ul>
        </div>
      </div>
      <div className="detail-content-box">
        <div
          className="board-content"
          dangerouslySetInnerHTML={createMarkup()}
        ></div>
        <div className="board-btn">
          <div className="btn-box">
            {userInfo.userInfo && (
              <>
                <button onClick={onEditHandler}>
                  <EditIcon className="btn-ico" />
                  수정
                </button>
                <button onClick={onDeleteHandler}>
                  <TrashIconB className="btn-ico" />
                  삭제
                </button>
              </>
            )}
            <button onClick={onDetailArticle}>
              <MenuIcon className="btn-ico" />
              목록
            </button>
          </div>
        </div>
      </div>
      <div className="boardListArea"></div>
    </div>
  );
};

export default ReferenceDetail;
