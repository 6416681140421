export const bMenus = [
  {
    value: 0,
    label: "전체",
    data: [{ value: 0, id: 0, label: "서브체" }],
  },
  {
    value: 1,
    label: "상사회사 설립",
    data: [
      {
        value: 0,
        id: 1,
        label: "주식회사 설립",
      },
      {
        value: 1,
        id: 2,
        label: "유한회사, 유한책임회사, 합명회사, 합자회사 설립",
      },
      {
        value: 2,
        id: 3,
        label:
          "민자사업 SPC, 태양광발전 및 신재생에너지 법인, 대학기술지주회사 및 자회사 설립",
      },
      {
        value: 3,
        id: 4,
        label: "농업회사법인, 사회적기업 법인, 근로복지기금 법인",
      },
      {
        value: 4,
        id: 5,
        label:
          "특별법에 의한 설립(자본시장법, 세무, 회계, 노무, 변리사 법인 등)",
      },
      {
        value: 5,
        id: 6,
        label: "외국인 투자법인(외자합작법인, 한국지사, 연락사무소 설치)",
      },
    ],
  },

  {
    value: 2,
    label: "법인전환/출자전환",
    data: [
      { value: 0, id: 7, label: "현물출자에 의한 전환" },
      { value: 1, id: 8, label: "사업양도양수에 의한 전환" },
      { value: 2, id: 9, label: "중소기업통합에 의한 전환" },
    ],
  },

  {
    value: 3,
    label: "자본금 변동",
    data: [
      { value: 0, id: 10, label: "증자(유상, 무상)" },
      { value: 1, id: 11, label: "감자(유상, 무상)" },
    ],
  },

  {
    value: 4,
    label: "합병/분할/조직변경",
    data: [
      { value: 0, id: 12, label: "합병" },
      { value: 1, id: 13, label: "분할" },
      { value: 2, id: 14, label: "조직변경" },
    ],
  },

  {
    value: 5,
    label: "사채/우선주/스톡옵션",
    data: [
      { value: 0, id: 15, label: "전환사채, 신주인수권부사채" },
      { value: 1, id: 16, label: "우선주, 상환주, 전환주식발행" },
      { value: 2, id: 17, label: "주식매수선택권(스톡옵션)" },
    ],
  },

  {
    value: 6,
    label: "해산/청산/법인부활",
    data: [
      { value: 0, id: 18, label: "해산, 청산" },
      { value: 1, id: 19, label: "계속, 부활" },
    ],
  },

  {
    value: 7,
    label: "비영리법인",
    data: [
      { value: 0, id: 20, label: "사단, 재단법인" },
      { value: 1, id: 21, label: "기타법인" },
    ],
  },

  {
    value: 8,
    label: "부동산 등기",
    data: [
      { value: 0, id: 22, label: "소유권보존, 이전등기" },
      { value: 1, id: 23, label: "상속등기, 외국인 상속" },
      { value: 2, id: 24, label: "근저당권, 전세권, 가등기" },
    ],
  },
];

export const bMainMenus = [
  {
    value: 1,
    label: "상사회사 설립",
    locateUrl: "establish",
    data: [
      {
        value: 0,
        id: 1,
        label: "주식회사 설립",
      },
      {
        value: 1,
        id: 2,
        label: "유한회사, 유한책임회사, 합명회사, 합자회사 설립",
      },
      {
        value: 2,
        id: 3,
        label: "민자사업SPC, 대학기술지주회사",
      },
      {
        value: 3,
        id: 4,
        label: "농업회사법인, 사회적기업 법인, 근로복지기금 법인",
      },
      {
        value: 4,
        id: 5,
        label:
          "특별법에 의한 설립(자본시장법, 세무, 회계, 노무, 변리사 법인 등)",
      },
      {
        value: 5,
        id: 6,
        label: "외국인 투자법인(외자합작법인, 한국지사, 연락사무소 설치)",
      },
    ],
  },

  {
    value: 2,
    label: "법인전환/출자전환",
    locateUrl: "conversion",
    data: [
      { value: 0, id: 7, label: "현물출자에 의한 전환" },
      { value: 1, id: 8, label: "사업양도양수에 의한 전환" },
      { value: 2, id: 9, label: "중소기업통합에 의한 전환" },
    ],
  },

  {
    value: 3,
    label: "자본금 변동",
    locateUrl: "capital",
    data: [
      { value: 0, id: 10, label: "증자(유상, 무상)" },
      { value: 1, id: 11, label: "감자(유상, 무상)" },
    ],
  },
  {
    value: 4,
    label: "합병/분할/조직변경",
    locateUrl: "reshuffle",
    data: [
      { value: 0, id: 12, label: "합병" },
      { value: 1, id: 13, label: "분할" },
      { value: 2, id: 14, label: "조직변경" },
    ],
  },
  {
    value: 5,
    label: "사채/우선주/스톡옵션",
    locateUrl: "stock",
    data: [
      { value: 0, id: 15, label: "전환사채, 신주인수권부사채" },
      { value: 1, id: 16, label: "우선주, 상환주, 전환주식발행" },
      { value: 2, id: 17, label: "주식매수선택권(스톡옵션)" },
    ],
  },

  {
    value: 6,
    label: "해산/청산/법인부활",
    locateUrl: "corporation",
    data: [
      { value: 0, id: 18, label: "해산, 청산" },
      { value: 1, id: 19, label: "계속, 부활" },
    ],
  },

  {
    value: 7,
    label: "비영리법인",
    locateUrl: "incorporatedAssociation",
    data: [
      { value: 0, id: 20, label: "사단, 재단법인" },
      { value: 1, id: 21, label: "기타법인" },
    ],
  },

  {
    value: 8,
    label: "부동산 등기",
    locateUrl: "realEstate",
    data: [
      { value: 0, id: 22, label: "소유권보존, 이전등기" },
      { value: 1, id: 23, label: "상속등기, 외국인 상속" },
      { value: 2, id: 24, label: "근저당권, 전세권, 가등기" },
    ],
  },
];

export const naviMenus = [
  {
    mainTitle: "인정법무사",
    mainLocate: "Injung",
    subMenu: [
      {
        isHit: false,
        subTitle: "대표인사말",
        label: "대표인사말",
        subLocate: "injung/greetings",
      },
      {
        isHit: false,
        subTitle: "법무사소개",
        label: "법무사소개",
        subLocate: "injung/intro",
      },
      {
        isHit: false,
        subTitle: "오시는 길",
        label: "오시는 길",
        subLocate: "injung/direction",
      },
      {
        isHit: false,
        subTitle: "주요업무",
        label: "주요업무",
        subLocate: "injung/partners",
      },
    ],
    mobileShowStatus: false,
    bgImage: null,
  },
  {
    mainTitle: "업무내용",
    mainLocate: "Business",
    subMenu: [
      {
        id: 1,
        value: 1,
        isHit: false,
        subTitle: "상사회사 설립",
        label: "상사회사 설립",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "상사회사 설립",
        subLocate: "business/category?id=1&sub=0",
        subMenu: [
          {
            value: 0,
            id: 1,
            isHit: false,
            subLocate: "business/category?id=1&sub=0",
            subTitle: "주식회사 설립",
            label: "주식회사 설립",
          },
          {
            value: 1,
            id: 2,
            isHit: false,
            subLocate: "business/category?id=1&sub=1",
            subTitle: "유한회사, 합자회사 설립",
            label: "유한회사, 유한책임회사, 합명회사, 합자회사 설립",
          },
          {
            value: 2,
            id: 3,
            isHit: false,
            subLocate: "business/category?id=1&sub=2",
            subTitle: "민자사업SPC, 대학기술지주회사",
            label: "민자사업SPC, 대학기술지주회사",
          },
          {
            value: 3,
            id: 4,
            isHit: false,
            subLocate: "business/category?id=1&sub=3",
            subTitle: "농업회사법인, 사회적기업법인",
            label: "농업회사법인, 사회적기업 법인, 근로복지기금 법인",
          },
          {
            value: 4,
            id: 5,
            isHit: false,
            subLocate: "business/category?id=1&sub=4",
            subTitle: "특별법 설립",
            label:
              "특별법에 의한 설립(자본시장법, 세무, 회계, 노무, 변리사 법인 등)",
          },
          {
            value: 5,
            id: 6,
            isHit: false,
            subLocate: "business/category?id=1&sub=5",
            subTitle: "외국인 투자법인",
            label: "외국인 투자법인(외자합작법인, 한국지사, 연락사무소 설치)",
          },
        ],
      },
      {
        id: 2,
        value: 2,
        isHit: false,
        subTitle: "법인전환/출자전환",
        label: "법인전환/출자전환",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "상사회사 설립",
        subLocate: "business/category?id=2&sub=0",
        subMenu: [
          {
            value: 0,
            id: 7,
            isHit: false,
            subLocate: "business/category?id=2&sub=0",
            subTitle: "현물출자",
            label: "현물출자에 의한 전환",
          },
          {
            value: 1,
            id: 8,
            isHit: false,
            subLocate: "business/category?id=2&sub=1",
            subTitle: "사업양도양수",
            label: "사업양도양수에 의한 전환",
          },
          {
            value: 2,
            id: 9,
            isHit: false,
            subLocate: "business/category?id=2&sub=2",
            subTitle: "중소기업통합",
            label: "중소기업통합에 의한 전환",
          },
        ],
      },
      {
        id: 3,
        value: 3,
        isHit: false,
        subTitle: "자본금 변동",
        label: "자본금 변동",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "상사회사 설립",
        subLocate: "business/category?id=3&sub=0",
        subMenu: [
          {
            value: 0,
            id: 10,
            isHit: false,
            subLocate: "business/category?id=3&sub=0",
            subTitle: "증자(유상, 무상)",
            label: "증자(유상, 무상)",
          },
          {
            value: 1,
            id: 11,
            isHit: false,
            subLocate: "business/category?id=3&sub=1",
            subTitle: "감자(유상, 무상)",
            label: "감자(유상, 무상)",
          },
        ],
      },
      {
        id: 4,
        value: 4,
        isHit: false,
        subTitle: "합병/분할/조직변경",
        label: "합병/분할/조직변경",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "상사회사 설립",

        subLocate: "business/category?id=4&sub=0",
        subMenu: [
          {
            value: 0,
            id: 12,
            isHit: false,
            subLocate: "business/category?id=4&sub=0",
            subTitle: "합병",
            label: "합병",
          },
          {
            value: 1,
            id: 13,
            isHit: false,
            subLocate: "business/category?id=4&sub=1",
            subTitle: "분할",
            label: "분할",
          },
          {
            value: 2,
            id: 14,
            isHit: false,
            subLocate: "business/category?id=4&sub=2",
            subTitle: "조직변경",
            label: "조직변경",
          },
        ],
      },
      {
        id: 5,
        value: 5,
        isHit: false,
        subTitle: "사채/우선주/스톡옵션",
        label: "사채/우선주/스톡옵션",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "상사회사 설립",
        subLocate: "business/category?id=5&sub=0",
        subMenu: [
          {
            value: 0,
            id: 15,
            isHit: false,
            subLocate: "business/category?id=5&sub=0",
            subTitle: "조직변경",
            label: "전환사채, 신주인수권부사채",
          },
          {
            value: 1,
            id: 16,
            isHit: false,
            subLocate: "business/category?id=5&sub=1",
            subTitle: "우선주, 상환주, 전환주식발행",
            label: "우선주, 상환주, 전환주식발행",
          },
          {
            value: 2,
            id: 17,
            isHit: false,
            subLocate: "business/category?id=5&sub=2",
            subTitle: "주식매수선택권",
            label: "주식매수선택권(스톡옵션)",
          },
        ],
      },
      {
        id: 6,
        value: 6,
        isHit: false,
        subTitle: "해산/청산/법인부활",
        label: "해산/청산/법인부활",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "상사회사 설립",
        subLocate: "business/category?id=6&sub=0",
        subMenu: [
          {
            value: 0,
            id: 18,
            isHit: false,
            subLocate: "business/category?id=6&sub=0",
            subTitle: "해산, 청산",
            label: "해산, 청산",
          },
          {
            value: 1,
            id: 19,
            isHit: false,
            subLocate: "business/category?id=6&sub=1",
            subTitle: "계속, 부활",
            label: "계속, 부활",
          },
        ],
      },
      {
        id: 7,
        value: 7,
        isHit: false,
        subTitle: "비영리법인",
        label: "비영리법인",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "상사회사 설립",
        subLocate: "business/category?id=7&sub=0",
        subMenu: [
          {
            value: 0,
            id: 20,
            isHit: false,
            subLocate: "business/category?id=7&sub=0",
            subTitle: "사단, 재단법인",
            label: "사단, 재단법인",
          },
          {
            value: 1,
            id: 21,
            isHit: false,
            subLocate: "business/category?id=7&sub=1",
            subTitle: "기타법인",
            label: "기타법인",
          },
        ],
      },
      {
        id: 8,
        value: 8,
        isHit: false,
        subTitle: "부동산 등기",
        label: "부동산 등기",
        bgImage: null,
        mainLocate: "Business",
        mainTitle: "부동산 등기",
        subLocate: "business/category?id=8&sub=0",
        subMenu: [
          {
            value: 0,
            id: 22,
            isHit: false,
            subLocate: "business/category?id=8&sub=0",
            subTitle: "소유권보존, 이전등기",
            label: "소유권보존, 이전등기",
          },
          {
            value: 1,
            id: 23,
            isHit: false,
            subLocate: "business/category?id=8&sub=1",
            subTitle: "상속등기, 외국인 상속",
            label: "상속등기, 외국인 상속",
          },
          {
            value: 2,
            id: 24,
            isHit: false,
            subLocate: "business/category?id=8&sub=2",
            subTitle: "근저당권, 전세권, 가등기",
            label: "근저당권, 전세권, 가등기",
          },
        ],
      },
    ],
    mobileShowStatus: false,
    bgImage: null,
  },
  {
    mainTitle: "고객지원",
    mainLocate: "Support",
    subMenu: [
      {
        isHit: false,
        label: "자료실",
        subLocate: "support/reference",
      },
      { isHit: false, label: "Q&A 게시판", subLocate: "support/question" },
      {
        isHit: false,
        label: "1:1 상담문의",
        subLocate: "support/reservation",
      },
    ],
    mobileShowStatus: false,
    bgImage: "/assets/images/common/background01.jpg",
  },
  {
    mainTitle: "최신경향 & 업무사례",
    mainLocate: "Trend",
    subMenu: [
      {
        isHit: false,
        label: "최신경향 & 업무사례",
        subLocate: "trend/case",
      },
    ],
    mobileShowStatus: false,
    bgImage: "/assets/images/common/background02.jpg",
  },
];

// {
//   value: 7,
//   label: "기타변경",
//   data: [
//     { value: 0, id: 21, label: "임원, 본점/지점변경" },
//     { value: 1, id: 22, label: "상호, 목적, 공고방법, 주식분할" },
//     { value: 2, id: 23, label: "주주총회, 이사회" },
//   ],
// },

// {
//   value: 10,
//   label: "민사분쟁",
//   data: [
//     { value: 0, id: 29, label: "가압류/채권양도" },
//     { value: 1, id: 30, label: "공탁절차" },
//     { value: 2, id: 31, label: "지급명령, 이행권고결정" },
//   ],
// },
