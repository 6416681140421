import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import ContentNav from "components/common/contentNav";
import TrendList from "pages/clientSupport/trend/trendList";
import "assets/css/clientSupport/board.css";

function TrendBoard() {
  const location = useLocation();

  return (
    <>
      <ContentNav />
      <div className="container">
        <div className="content-box">
          {location.pathname.includes("detail") ? (
            <Outlet />
          ) : location.pathname.includes("write") ? (
            <Outlet />
          ) : location.pathname.includes("edit") ? (
            <Outlet />
          ) : (
            <TrendList />
          )}
        </div>
      </div>
    </>
  );
}

export default TrendBoard;
