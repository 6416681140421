import React from "react";
import { RequiredAuth } from "components/common/requiredAuth";
import Home from "pages/main/home";

//인정법무사소개
import Intro from "pages/injungLaw/intro";
import Greetings from "pages/injungLaw/greetings";
import Direction from "pages/injungLaw/direction";
import Partners from "pages/injungLaw/partners";

//업무
import Business from "pages/business/business";

//고객지원
import ReferenceBoard from "pages/clientSupport/reference/referenceBoard";
import ReferenceDetail from "pages/clientSupport/reference/referenceDetail";
import ReferenceEdit from "pages/clientSupport/reference/referenceEdit";
import ReferenceWrite from "pages/clientSupport/reference/referenceWrite";
import QuestionBoard from "pages/clientSupport/question/questionBoard";
import QuestionDetail from "pages/clientSupport/question/questionDetail";
import QuestionEdit from "pages/clientSupport/question/questionEdit";
import QuestionWrite from "pages/clientSupport/question/questionWrite";
import Reservation from "pages/clientSupport/reservation/reservation";

//최신경향
import TrendBoard from "pages/clientSupport/trend/trendBoard";
import TrendDetail from "pages/clientSupport/trend/trendDetail";
import TrendEdit from "pages/clientSupport/trend/trendEdit";
import TrendWrite from "pages/clientSupport/trend/trendWrite";

//인정유투브
import InjungYoutube from "pages/youtube/injungYoutube";

//기타
import Login from "pages/user/login";

const router = [
  /* 메인페이지 */
  { path: "/", element: <Home /> },
  {
    /* 인정 법무사 소개  */
    path: "/injung",
    children: [
      {
        /* 대표인사말 */
        path: `greetings`,
        element: <Greetings />,
      },
      {
        /* 법무사 소개 */
        path: `intro`,
        element: <Intro />,
      },
      {
        /* 오시는 길 */
        path: `direction`,
        element: <Direction />,
      },
      {
        /* 파트너 쉽 */
        path: `partners`,
        element: <Partners />,
      },
    ],
  },
  /* 업무내용 */
  {
    path: "/business",
    children: [
      {
        path: "category",
        element: <Business />,
      },
    ],
  },

  {
    /* 고객지원 */
    path: "/support",
    children: [
      /* 상담예약 */
      { path: "reservation", element: <Reservation /> },
      {
        path: "reference",
        element: <ReferenceBoard />,
        children: [
          {
            path: `write`,
            element: (
              <RequiredAuth>
                <ReferenceWrite />
              </RequiredAuth>
            ),
          },
          {
            path: `edit/:postId`,
            element: (
              <RequiredAuth>
                <ReferenceEdit />
              </RequiredAuth>
            ),
          },
          {
            path: `detail/:postId`,
            element: <ReferenceDetail />,
          },
        ],
      },
      {
        path: "question",
        element: <QuestionBoard />,
        children: [
          {
            path: `write`,
            element: <QuestionWrite />,
          },
          {
            path: `edit/:postId`,
            element: <QuestionEdit />,
          },
          {
            path: `detail/:postId`,
            element: <QuestionDetail />,
          },
        ],
      },
    ],
  },
  {
    /* 최신경향 & 업무사례  */
    path: "/trend",
    children: [
      {
        path: `case`,
        element: <TrendBoard />,
        children: [
          {
            path: `write`,
            element: (
              <RequiredAuth>
                <TrendWrite />
              </RequiredAuth>
            ),
          },
          {
            path: `edit/:postId`,
            element: (
              <RequiredAuth>
                <TrendEdit />
              </RequiredAuth>
            ),
          },
          {
            path: `detail/:postId`,
            element: <TrendDetail />,
          },
        ],
      },
    ],
  },
  /* 유튜브 채널 */
  // { path: "/youtube", element: <InjungYoutube /> },

  /* 유저 - 로그인 등 */
  { path: "/login", element: <Login /> },
];

export default router;
