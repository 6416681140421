const BusinessContent = [
  {
    id: 1,
    subMenu: [
      {
        value: 0,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>주식회사</span>
                    </div>
                    <div className="content">
                      <span>
                        주식회사란 자본을 주식으로 분할하여 그 분할된 자본에
                        대한 지분을 가진 주주가 동 주식 인수가액에 대한
                        출자의무를 부담하는 동시에 주식에 내재된 권리(배당받을
                        권리인 자익권과 주주총회에 참석하여 의사결정 할 공익권
                        등)를 행사하는 유한책임회사이다.
                      </span>
                    </div>
                  </div>

                  <div className="type2">
                    <div className="title">
                      <span>설립절차</span>
                    </div>
                    <div className="sub-title">
                      <span>
                        발기인이 주식전부를 인수하는 발기설립과 주식일부를 제
                        3자로부터 모집하는 모집설립이 있다. 이를 도식화하면
                        아래와 같다
                      </span>
                    </div>
                    <div className="info">
                      <span>
                        *발기인의 정관작성(상법288조) / *주식발행사항
                        결정(상법291조)
                      </span>
                    </div>
                    <div className="chart-area">
                      <div className="chart">
                        <div className="rt-1">
                          <span>발기설립의 경우</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 발기인의 주식전부 인수</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 주금납입</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 이사, 감사 선임</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 설립경과에 대한 조사보고</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 설립등기</span>
                        </div>
                      </div>
                      <div className="chart">
                        <div className="rt-2">
                          <span>모집설립의 경우</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 발기인의 주식일부 인수</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 주주모집 및 청약</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 주금납입</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 창립총회 및 임원선임</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 설림경과 조사</span>
                        </div>
                        <div className="cont">
                          <span>⦁ 설립 등기</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>설립을 위해 확정될 내용(정관 기재사항)</span>
                    </div>
                    <div className="sub-title">
                      <span>
                        회사 설립을 위해 우선 확정되어야 할 내용은 다음과 같다
                      </span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>회사상호</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 동일상호 존부는 저희 사무실로 연락 후 확인하시기
                              바라며, 대법원홈페이지에서도 조회가 가능합니다.
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 주식회사의 위치는 선택절 (ex. 현대해상화재보험
                              주식회사, 주식회사 삼성화재)
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 영문 상호는 한글 상호 뒤 괄호를 부하여
                              알파벳으로 병기 가능함
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>본점소재지</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 임대차계약서는 설립등기 첨부할 서면은 아니지만
                              사업자등록신청시에는 세무소에 사본 제출
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 제출 전대차 계약인 경우에는 건물주의 동의서 첨부
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 집합 건물인 경우에는 건물호까지 기재. 단,
                              일반건물인 경우에는 지번 또는 층까지 기재해도 무방
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>공고방법 및 1주의 금액</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 회사가 합병, 자본감소 등의 업무를 수행하는 경우
                              상법 절차에 따라 제 3자 보호를 위해 시사에 관한
                              일간 신문에 그 사실을 일정기간 게재해야하는바,
                              법인 설립시 향후 공고 낼 신문을 미리 정해서
                              등기해야함. 통상 한국경제신문 또는 매일경제신문
                              등을 많이 이용함
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 1주의 금액은 상법상 금 100원으로 되어있으나 통상
                              금 1,000원 또는 금5,000원으로 함
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>4</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>사업목적</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 한국표준산업분류표를 참고할 수 있으며, 통상 업종
                              및 업태를 동시 기재함(ex:1.의류 도소매업)
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 첨단 기술업종 및 지방세법시행령 제 102조 각항의
                              경우 설립등록세액을 감면 받을 수 있음
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>5</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>발기인의 인적사항 및 출자지분</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 발기인의 성명, 주민번호, 주소와 각 출자지분
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 출자지분 50%초과하는 주주 (상속증여세법상
                              특수관계인 포함)를 과점주주라고 하며 동인들은
                              과점비율에 따라 국세에 대한 2차납세 의무가 있음
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>6</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>임원 (이사,감사)의 인적사항</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 대표이사, 이사, 감사의 성명, 주민등록번호, 주소
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 자본금 10억미만 회사는 1인이사 회사도 가능하며
                              감사는 두지 않을 수 있음
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 이사는 사내이사, 사외이사, 기타비상무이사로 구분
                              (통상 사내이사로 함)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <span>필요서류</span>
                    </div>
                    <div className="sub-info">
                      <span>
                        * 상기 인감증명서 및 주민등록등(초)본은 모두 등기신청일
                        기준 3개월 내에 발급된 것이어야 함 <br />* 개인 인감도장
                        반출이 불가한 경우 취임승낙서, 법인인감신고서
                        (대표이사의 경우) 및 공증위임장(모집설립의 경우)에
                        인감날인 후 목도장 준비
                      </span>
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="rt">
                          <img src="/assets/images/common/business_need01.png" />
                          <span>발기설립의 경우(자본금 10억 미만)</span>
                        </div>
                        <div className="rt-3" />
                        <div className="cont">
                          <span>
                            ⦁ 잔고증명서 (발기인명의의 보통 예금계좌에
                            설립자본금 이상의 잔액이 있다는 증명이어야 함)
                            <br /> <br />
                            ⦁ 임원(이사, 감사)의 개인인감증명서 2통,
                            주민등록등본 또는 초본 1통, 개인인감도장
                            <br /> <br />⦁ 발기인전원의 도장(목도장도 무방함)
                          </span>
                        </div>
                      </div>
                      <div className="doc">
                        <div className="rt">
                          <img src="/assets/images/common/business_need02.png" />
                          <span>모집 설립의 경우</span>
                        </div>
                        <div className="rt-3" />
                        <div className="cont">
                          <span>
                            ⦁ 주식납입금보관증명서 <br />
                            (정관공증 후 관련 의사록을 은행에 제출하여 발급
                            받음)
                            <br /> <br />
                            ⦁ 임원(이사, 감사)의 개인인감증명서 2통,
                            주민등록등본 또는 초본 1통, 개인인감도장
                            <br /> <br />⦁ 출자지분 3분의2이상 주주의 인감증명서
                            1통, 인감도장 <br />
                            (나머지 주주전원의 목도장)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        value: 1,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>유한회사</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>주식회사와의 차이점</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 유한회사는 주식회사와 함께 물적회사로서 그
                              설립절차는 주식회사의 발기설립과 유사하다.
                              주식회사와의 차이점은 ①유한회사의 사원은 50인
                              이하로 제한되며(상법 제543조), ②이사의 임기에 대한
                              제한조항이 없고 이사회제도도 법정화 되어 있지
                              않다. 한편 ③유한회사는 소규모성으로 인해
                              자본증가시 사원의 공모가 인정되지 아니하며(상법
                              제589조), 사채제도가 없다(상법 제600조)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>설립절차</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 소규모 가족기업으로서 법인사업자를 원한다면
                              유한회사 형태가 적법하다. 설립절차는 ①사원의
                              정관작성 ②이사, 감사선임(감사는 임의기관 이므로
                              두지 않을 수 있다) ③출자이행 ④ 설립등기의 순서로
                              진행된다.
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>필요서류</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 임원진 전원의 인감도장, 인감증명서,
                              주민등록등초본 각1통
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 출자자 전원의 목도장, 사무소 임대차계약서사본
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>

                  <div className="type1">
                    <div className="title">
                      <span>합명회사 및 합자회사</span>
                    </div>
                    <div className="content">
                      <span>
                        합명회사란 회사의 채무에 대해 사원들이 무한연대책임을
                        부담하는 인적회사이고 합자회사란 무한책임사원과
                        유한책임사원이 결합된 인적회사 이다. 따라서 합명회사는
                        민법상의 조합과 유사한 회사형태를 띠고 있으며 그 책임의
                        무한성으로 인해 출자자(사원) 상호간의 신뢰가 중요하며,
                        합자회사는 구성원 중 유한책임사원이 있는 것을 제외하면
                        합명회사와 같다. 한편 무한책임사원의 출자는 재산출자
                        외에 신용, 노무출자도 가능 하나 유한책임사원은 신용이나
                        노무를 출자의 목적으로 하지 못하며(상법 제272조) 그
                        책임의 유한성으로 인해 회사의 업무집행이나 대표권을
                        행사하지 못한다(상법 제278조)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        value: 2,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>민자사업SPC, 대학기술지주회사</span>
                  </div>
                  <div className="content">
                    <span>
                      사회기반시설에 대한 민간투자법에 의한 설립개요 학교, 도로,
                      하수관거시설 등 사회기반시설에 대한건설을 위해 정부나
                      지방자치단체는 민간자본을 이용코자 민간투자법에 의거
                      사업기본계획을 고시하고 신청적격자 심사 후
                      우선협상대상자를 선정하여 실시협약을 체결하게 된다. 따라서
                      우선협상대상자로 지정된 신청자는 본 사업을 위한
                      특수목적법인(SPC)을 설립하고 향후 기성고에 따른 자금지출을
                      위해 재무적 투자자로부터 유상증자를 통해 자기자본을
                      충당하게 되며 시설물 건립 후에는 소유권이전 및 임대,
                      운영수입을 통해 투하자본을 회수하게 된다.
                    </span>
                  </div>
                  <div className="info">
                    <span>
                      저희 사무소는 민간투자법에 의거 현대건설, 벽산건설,
                      이수건설, 코오롱건설 등이 주된 CI로서 사업시행한 다양한
                      BTL, BTO법인을 설립, 관리 하고 있습니다. 문의사항은
                      언제든지 연락주세요.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>대학기술지주회사 개요</span>
                  </div>
                  <div className="content">
                    <span>
                      대학내 부설기관인 산학협력단이 보유한 특허권 등을
                      산업발전으로 연계하기 위하여 제정된
                      산업교육진흥및산학협력촉진에관한법률에 의거 산학협력단은
                      대학기술지주회사를 설립할 수 있으며 교육과학기술부장관의
                      인가를 받아야 한다. 또한 기술지주회사는 주주총회의 결의를
                      통해 자회사를 설립할 수 있으며 지주회사가 소유한 특허권
                      등의 기술을 현물로 출자전환할 수 있다.
                    </span>
                  </div>
                  <div className="info">
                    <span>
                      저희 사무소는 서강대, 동국대, 부산대, 인천대 등
                      대학기술지주회사를 설립하고 그 기술을 자회사로 이전한
                      업무경험을 축적하고 있습니다. 궁금한사항은 언제든지
                      연락주시기 바랍니다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 3,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>농업회사, 영농조합</span>
                  </div>
                  <div className="content">
                    <span>
                      농어업경영체 육성 및 지원에관한법률에 의거 농업경영,
                      농산물의 유통 판매 가공 등의 목적으로 농업회사를 설립할 수
                      있으며, 또한 농업인 5인 이상의 조합원으로 참여하여
                      영농조합법인을 설립할 수 있다. 농업회사는 비농업인의
                      출자지분이 90%를 초과할 수 없으며 업무집행권자의
                      3분의1이상이 농업인이여야 한다. 농업법인은 농업소득에 대해
                      법인세를 면제할 뿐만 아니라 각종 조세혜택으로 최근 많은
                      관심을 받고 있다.
                    </span>
                  </div>
                  <div className="info">
                    <span>
                      저희 사무소는 농업회사법인 (주)인천바이오,
                      (주)대한에프에스, 어업회사법인 (주)신도천일염 및
                      영농조합법인 설립에 대한 다양한 노하우를 가지고 있습니다.
                      언제든지 문의 주시면 성심껏 답변해 드리겠습니다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>사회적기업</span>
                  </div>
                  <div className="content">
                    <span>
                      사회 취약계층에게 사회서비스 또는 일자리를 제공하거나
                      지역사회에 공헌함으로써 사회적 목적을 추구하면서 재화 및
                      서비스의 생산, 판매 등 영업활동을 하는 기업을 말하며
                      고용노동부장관의 인증을 받아야 한다(사회적기업육성법
                      제7조). 한편 상법상의 회사인 경우 배분가능 이익의
                      3분의2이상을 사회적 목적을 위해 사용하여야 인증요건에
                      해당할 수 있으며 고용노동부장관은 사회적기업을 인증한
                      경우에는 그 사실을 관보에 게재하여야 한다(동법 제8조).
                    </span>
                  </div>
                  <div className="info">
                    <span>
                      저희 사무소는 (유)행복도시락, (주)식물농장,
                      (주)나눔클리닉, (주)고마운사람 등 다수의 사회적기업
                      설립업무를 수행한 풍부한 경험을 공유하고 있습니다.
                      문의사항은 언제든지 연락 주시기 바랍니다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 4,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>자본시장과 금융투자업에 관한 법률에 의한 설립</span>
                    </div>
                    <div className="content">
                      <span>
                        자본시장의 공정한 경쟁을 촉진하고 건전한 금융투자업
                        육성을 위해 설립된 자본시장법에 따른 집합투자기구로서 ①
                        투자신탁 ②상법상의 투자 회사, 투자유한회사, 투자합자회사
                        ③ 민법상의 투자조합 ④ 지분증권을 사모로만 발행하는
                        사모투자전문회사를 설립할 수 있다. 상기 집합투자기구는
                        설립 후 금융위원회에 등록해야 하며(법 제182조), 특히
                        사모투자전문회사는 설립등기일로부터 2주내에 금융위원회에
                        등록하여야 한다(법 제268조). 한편 상기 집합투자기구가
                        금융투자업을 수행하기 위해서는 금융위원회의 인가를
                        받아야 한다(법 제11조).
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>회계법인, 세무법인, 노무법인, 변리사법인</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 회계법인은 공인회계사 10명 이상으로 설립할 수
                              있으며 자본금은 5억원 이상이어야 한다. 정관상에는
                              각 사원의 출자좌수를 기재해야 하며 설립후
                              금융위원회에 등록하여야 한다(회계사법 제24조).
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 세무법인은 세무사5명이상으로 설립할 수 있으며
                              자본금은 2억원 이상이어야 한다. 정관상에는 목적
                              명칭 등 일반적인 사항 이외에 결손금 보전에 관한
                              사항을 기재해야 하고 기획재정부장관에게 등록한다
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 노무법인은 개업노무사 2인 이상으로 구성되며
                              고용노동부장관의 설립인가를 받은날로부터 14일
                              이내에 설립등기를 신청하여야 한다 (19조의5).
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>4</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 변리사는 5명이상의 변리사를 구성원으로 하여
                              특허청장의 인가를 받아 특허법인을 설립할 수
                              있다(변리사법 제6조의3)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        value: 5,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>개념정리</span>
                  </div>
                  <div className="content">
                    <span>
                      외국인이 국내에 투자하는 형태는 ① 상법에 의한 독자법인을
                      만드는 경우(외국인만 출자한 독립법인 또는 국내법인과
                      공동투자 한 합작법인) ②외국본사의 한국지사(Branch
                      Office)를 설치하는 경우 및 ③ 연락사무소(Liaison Office)를
                      설치하는 경우로 대별해 볼 수 있다. 독자법인의 경우 외국인
                      주주는 주식수에 비례하여 의사결정에 참여하고 이익배당 등을
                      통해 투하자본금을 회수하게 되고 한국지사의 경우에는 의사
                      회계처리가 본사와 연동되어 있는 형태이며, 연락사무소의
                      경우에는 법인격이 없는 상태인바 영리행위를 할 수는 없고
                      현지 시장조사 등 수집처로서의 역할을 수행하게 된다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>투자절차</span>
                  </div>
                  <div className="content">
                    <span>
                      외국인투자란 1인당 투자금액 1억원 이상으로서 의결권 있는
                      주식총수출자총액의 10%이상을 소유하는 것을
                      말하며(외국인투자촉진법 제2조), 신주취득 뿐만 아니라
                      기존주식의 취득에 의한 투자도 가능하다(법 제5조, 6조).
                      외국인투자를 하려는 경우 지식경제부장관어 신고를 하여야
                      하며 신고절차는 대한무역투자진흥공사나
                      외국환은행장(시중은행을 말함)에게 신고서를 제출해야
                      한다(시행규칙 제2조).
                    </span>
                  </div>
                  <div className="info">
                    <span>
                      저희 사무소는 (주)토다이, (주)허메스홀딩스,
                      (주)스타츠코리아, (주)어번뷰코리아 등 다수의 다국적 기업에
                      대한 법인설립, 지사설치 및 투자 업무 경험을 상시 자문으로
                      공유하고 있습니다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    id: 2,
    subMenu: [
      {
        value: 0,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>개념</span>
                  </div>
                  <div className="content">
                    <span>
                      현물출자의 목적물은 특별한 제한이 없고 대차대조표상
                      자산으로 계상할 수 있는 재산이면 채권, 특허권, 부동산등 그
                      종류여하를 불문하다. 단, 법인설립시 현물출자는
                      변태설립사항에 해당하므로 출자목적물의 종류 및 평가가액이
                      정관에 기재되어야 하며, 자본금증자를 위한 현물출자의
                      경우에는 이사회에서 출자목적물의 종류, 가액 및 이에 부여할
                      주식의 종류와 등을 결정하여야 한다(상법 제416조).
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>장, 단점</span>
                  </div>
                  <div className="content">
                    <span>
                      현물출자는 채권, 기계기구, 부동산등을 자본금화할 수
                      있으므로 현금 없이 법인설립 또는 자본금 증액이 가능하다는
                      점에서 큰 잇점이 있다. 그러나 현물을 자본금화하기 위해서는
                      우선 평가기관의 평가가 선행되어야 하며(채권의 경우
                      공인회계사, 특허권 및 부동산의 경우 감정평가사가 평가함)
                      이사회 또는 주주총회의 의결 후 법원의 인가를 득하여야
                      하므로 현금에 의한 경우보다 시간, 비용이 소요(법원인가에
                      통상 3주정도 소요됨) 된다는 단점이 있다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 1,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>개념</span>
                  </div>
                  <div className="content">
                    <span>
                      개인기업은 의사결정이 신속하나 무한책임을 부담하며
                      소득세법에 의해 과세된다. 한편 법인기업은 등기부상
                      관리되므로 대외 신뢰도를 확보할 수 있고 원칙적으로
                      유한책임을 지며 법인세법에 의해 과세되는데, 법인세법상에는
                      다양한 세제혜택이 있다. 따라서 개인사업자는 일정한
                      매출액에 이르면 법인전환을 염두해 둘 수 밖에 없으며 이때
                      가장 많이 활용되는 방법이 사업양수도에 의한 법인전환이다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>절차</span>
                  </div>
                  <div className="content">
                    <span>
                      사업양수도방법은 크게 보면 ①개인사업자의 법인설립
                      ②개인사업자와 법인사업자간포괄양수도계약 ③개인기업의
                      결산의 절차로 이루어진다. 한편 조세특례제한법 제32조의
                      조세혜택을 받기 위해서는 신설법인의 자본금이 개인기업의
                      순자산평가액 이상이어야 하므로 이 경우에는 먼저 개인기업의
                      순자산평가액 추정이 선행되어야 한다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 2,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>개념</span>
                  </div>
                  <div className="content">
                    <span>
                      중소기업자는 개인과 법인을 구분하지 않는 통합유형을 대별해
                      보면 ①개인+개인법인 ②개인+법인법인 ③인+법인법인의 형태가
                      있으나 실무상 ①, ②의 형태를 통해 법인으로 전환된다. 특히
                      개인기업과 법인기업간의 통합은 법인을 신설하지 않고
                      기존법인에 개인기업의 재산을 현물 출자하여 증자하는 형태로
                      진행된다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>특이점</span>
                  </div>
                  <div className="content">
                    <span>
                      조세특례제한법 제31조의 요건에 맞는 중소기업통합에 의한
                      법인전환의 경우에는 ① 양도소득세를 이월과세 받을 뿐만
                      아니라 ②부동산이전 등기에 따른 취·등록세액을
                      면제받는다(감면세액의 20%를 농특세로 납부하며,
                      국민주택채권은 매입의무 있음).
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    id: 3,
    subMenu: [
      {
        value: 0,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>개념</span>
                  </div>
                  <div className="content">
                    <span>
                      주식회사가 외부자금을 조달하는 방법으로 유상증자와
                      사채발행이 있다. 유상증자는 영구적인 자기자본화 되므로
                      상환부담이 없으나 사채는 타인자본으로서 회사의 부채이므로
                      상환해야 하는 부담이 있다. 한편 외부자금 조달 없이
                      대차대조표상 이익준비금의 전부 또는 일부를 자본에 전입할
                      수 있으며(상법 제461조), 주식발행초과금도 자본준비금으로서
                      자본에 전입할 수도 있다(상법 제459조).
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>절차</span>
                  </div>
                  <div className="content">
                    <span>
                      유상증자는 이사회결의에 따라 주주배정에 의한 방식과 정관에
                      의한 제3자배정 방식이 있다. 증자후에는 주주간 지분비율이
                      변경되어 지배구조에 첨예한 이해관계를 가진 경우가 있으므로
                      주주배정에 의거 기존 주주에게 신주인수 기회를 부여하는
                      것이 다툼의 여지를 불식시킬 수 있으나, 긴급한 자금조달
                      등의 목적으로 정관규정에 의거 바로 제3자에게 신주전부를
                      배정하는 경우도 있다. 한편 무상증자는 실무상 그 재원을
                      입증해야 하므로 정기주주총회에서 승인받은 대차대조표 또는
                      국세청에서 발행하는 표준재무제표증명원을 첨부해야 한다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <div className="title-box">
                      <img src="/assets/images/common/business_need01.png" />
                      <span>필요서류</span>
                    </div>
                    <div className="rt-4" />
                  </div>

                  <div className="content">
                    <span>
                      ⦁ 이사과반수의 인감도장,인감증명서 1통
                      <br />
                      ⦁ 이사수 2인 이하인 회사는 주식수 25% 이상주주의 인감도장
                      및 인감증명서 1통
                      <br />
                      ⦁ 출자자 및 주주전원의 목도장
                      <br />
                      ⦁ 법인도장, 법인인감증명서 1통, 정관사본, 사업자등록증사본
                      <br />⦁ 주금납입보관증명서(10억미만의 경우 잔고증명서 1부)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 1,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>개념</span>
                  </div>
                  <div className="content">
                    <span>
                      회사는 통상경영악화에 따른 재무구조 건전성 확보와 자본금의
                      환급(또는 투하자본의 반환)을 목적으로 자본감소를 단행한다.
                      경영악화의 경우 에는 환급금없이 자본을 감소시키는 경우가
                      대부분이며(무상강제감자), 자본금 환급의 경우에는
                      일정가액으로 회사가 주식을 매입하여 소각하는
                      형태이다(유상감자). 한편 무상강제감자의 방법에는 주식수를
                      줄이는 방법(주식병합, 주식소각) 1주당 액면가를 줄이는
                      방법이 있으며 주식 소각의 경우에도 주주의 동의에 의해
                      소각하는 임의소각과 회사가 일방적으로 소각하는 강제소각이
                      있다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>절차</span>
                  </div>
                  <div className="content">
                    <span>
                      자본감소는 회사의 채권자와 이해관계인에게 큰 손실을 초래할
                      수 있으므로 엄격한 법정절차를 준수해야 하는바 ① 주주총회의
                      특별결의로 자본 감소 방법결정 ②채권자보호절차(1개월 이상의
                      신문공고 및 개별채권자에 대한 최고) ③주식병합 또는 소각 ④
                      자본감소등기의 절차로 진행된다. 단, 자본감소의 효력은
                      주식의 소각 또는 병합절차가 종료됨으로써 발생하며 등기는
                      효력요건이 아니다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <div className="title-box">
                      <img src="/assets/images/common/business_need01.png" />
                      <span>필요서류</span>
                    </div>
                    <div className="rt-4" />
                  </div>

                  <div className="content">
                    <span>
                      ⦁ 주식수 34% 이상주주의 인감도장, 인감증명서 1통
                      <br />
                      ⦁ 주주명부, 신문공고문(당사 대행)
                      <br />
                      ⦁ 유상감자의 경우에는 소각하는 주주의 도장
                      <br />⦁ 법인인감도장, 법인인감증명서 1통, 정관사본,
                      사업자등록증사본
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    id: 4,
    subMenu: [
      {
        value: 0,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>개념</span>
                    </div>
                    <div className="content">
                      <span>
                        회사는 기업활동과 관련하여 규모를 키움으로써
                        시장경쟁력을 확보할 수 있을 뿐만 아니라 기업결합을 통해
                        경비절감 및 품질향상을 도모하고 시너지효과를
                        극대화하고자 한다. 상법상의 합병이란 2개이상의 회사가
                        결합되어 1개의 회사가 되는 과정이며, 이때 소멸되는
                        회사는 청산절차를 거치지 않고 해산되며 모든 권리와
                        의무는 존속회사에 포괄적으로 승계된다. 그 형태에 따라
                        흡수합병, 신설합병, 분할합병 등이 있으나 실무상 대부분이
                        흡수합병의 형태를 띠고있다.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>절차</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 합병계약(대표이사간 합병방식에 관한 합의)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 합병대차대조표 공시(합병결의 주주총회2주전부터
                              합병후 6월까지)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 합병결의(주주총회의 특별결의사항)</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>4</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 합병반대주주의 주식매수청구권행사</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>5</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 채권자보호절차(합병결의 후 2주내에 1월이상의
                              공고 및 최고)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>6</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 총회개최(흡수합병의 경우에는 보고총회,
                              신설합병의 경우에는 창립총회)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />

                      <li>
                        <div className="num">
                          <span>7</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 합병등기</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />

                      <li>
                        <div className="num">
                          <span>8</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 사후공시(합병에 관한 사항을 기재한 서면을 6월
                              본점에 비치)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <div className="title-box">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>필요서류(회사별)</span>
                      </div>
                      <div className="rt-4" />
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="cont1">
                          <span>
                            ⦁ 주식수 34% 이상 주주의 인감도장, 인감증명서1통
                            <br /> <br />
                            ⦁ 주주명부, 신문공고문(당사 대행)
                            <br /> <br />⦁ 합병대차대조표(승계재산목록 포함)
                            <br /> <br />⦁ 합병법인인감도장, 법인인감증명서 1통,
                            정관사본, 사업자등록증사본
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        value: 1,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>개념</span>
                    </div>
                    <div className="content">
                      <span>
                        회사분할이란 1개 회사의 영업을 둘 이상으로 분리하여
                        분리된 영업재산을 자본으로 회사를 신설하거나 다른 회사와
                        합병시키는 것을 말하며, 분할전 회사(피분할회사)의
                        권리의무는 분할 후 회사에 포괄승계된다. 회사분할은
                        다양한 목적으로 행해지면 보편적으로 보면 ①
                        특정사업부문을 분할하여 전문화, 효율화 ②부진사업이나
                        적자사업을 분리하여 경영의 효율화 ③ 이익분산에 의한 절세
                        ④ 주주 상호간에 이해관계가 대립되어 분리시키고자 하는
                        때에 일어나게 된다.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>절차</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 분할계획서 또는 분할합병계약서 작성</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 분할계약서 또는 분할합병계약서의
                              사전공시(분할승인 주주총회 2주전부터 분할등기일
                              또는 분할합병한 날 이후 6월간)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 분할결의(분할계획서등에 대한 주주총회의
                              특별결의)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>4</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 주식매수청구권(분할합병의 경우에만 인정)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>5</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 분할관련사항의 신고(주권상장법인 또는
                              협회등록법인의 경우 금융감독위원회와 증권거래소
                              또는 협회에 신고)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>6</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 채권자보호절차(분할승인결의가 있는 날로부터 2주
                              내에 1월 이상의 기간을 정하여 공고, 최고)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />

                      <li>
                        <div className="num">
                          <span>7</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 분할등기(본점2주, 지점3주내)</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <div className="title-box">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>필요서류(회사별)</span>
                      </div>
                      <div className="rt-4" />
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="cont1">
                          <span>
                            ⦁ 주식수 34% 이상주주의 인감도장, 인감증명서 1통
                            <br /> <br />
                            ⦁ 주주명부, 신문공고문(당사 대행)
                            <br /> <br />⦁ 분할대차대조표(승계재산목록 포함)
                            <br /> <br />⦁ 신설분할의 경우 임원진의 인감도장,
                            인감증명서 1통, 주민등록초본1통
                            <br /> <br />⦁ 법인인감도장, 법인인감증명서 1통,
                            정관사본, 사업자등록증사본
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        value: 2,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>개념</span>
                    </div>
                    <div className="content">
                      <span>
                        조직변경이란 회사가 그 인격의 동일성을 유지하면서 다른
                        종류의 회사로 전환하는 것을 말한다. 유한회사를
                        주식회사로 또는 주식회사를 유한 회사로 변경하는 경우가
                        대부분이며, 간혹 합명회사를 합자회사로 또는 합자회사를
                        합명회사로 조직변경하는 경우도 있다. 단 인적회사와 물적
                        회사상호간의 조직변경은 허용되지 않는다.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>절차</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>주식회사에서 유한회사로의 조직변경</span>
                          </div>
                          <div className="l-cont">
                            <span>① 총주주의 일치에 의한 총회결의</span>
                          </div>
                          <div className="l-cont">
                            <span>② 사채상환</span>
                          </div>
                          <div className="l-cont">
                            <span>③ 채권자보호절차</span>
                          </div>
                          <div className="l-cont">
                            <span>④ 조직변경등기</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>유한회사에서 주식회사로의 조직변경</span>
                          </div>
                          <div className="l-cont">
                            <span>① 사원총회 결의 </span>
                          </div>
                          <div className="l-cont">
                            <span>② 법원의 인가 </span>
                          </div>
                          <div className="l-cont">
                            <span>③ 채권자보호절차 </span>
                          </div>
                          <div className="l-cont">
                            <span>④ 조직변경등기</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <div className="title-box">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>필요서류(회사별)</span>
                      </div>
                      <div className="rt-4" />
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="cont1">
                          <span>
                            ⦁ 주주(출자자)전원의 인감도장, 인감증명서1통
                            <br /> <br />
                            ⦁ 주주(출자)명부, 신문공고문(당사 대행)
                            <br /> <br />⦁ 임원들의 인감도장, 인감증명서 1통,
                            주민등록초본1통
                            <br /> <br />⦁ 대차대조표(주식회사의 경우
                            사채상환증명서)
                            <br /> <br />⦁ 법인인감도장, 법인인감증명서 1통,
                            정관사본, 사업자등록증사본
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
    ],
  },
  {
    id: 5,
    subMenu: [
      {
        value: 0,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>개념</span>
                    </div>
                    <div className="content">
                      <span>
                        회사는 외부자금 조달을 용이하게 하고자 이사회의 결의로
                        전환사채 또는 신주인수권부사채를 발행할 수 있다.
                        주식으로 전환할 수 있는 권리가 인정된 사채를
                        전환사채(CW)라고 하고, 사채의 발행조건으로 사채권자에게
                        신주인수권을 부여한 사채를 신주인수권부사채(BW)라고
                        한다. 양자의 차이는 전환사채는전환권 행사에 의하여
                        사채권이 소멸하고 주식으로 변환됨에 반하여
                        신주인수권부사채는 사채권과 신주인수권이 별개로 존재
                        한다는 점이다. 상기 사채들은 발행회사 입장에서 보면
                        보통사채보다 이율이 낮다는 점에서 유리하고 사채인수권자
                        입장에서는 회사의 장래를 보고 주식을 취득할 수 있는
                        기회를 가진다는 점에서 투자의 방편으로 활용될 수 있다는
                        장점이 있다.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>절차</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 이사회 결의(사채발행사항 결정)</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 배정일 공고(사채인수권을 행사 주주 확정)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              ⦁ 주주에 대한 최고 실권(청약일의 2주전 통지)
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>4</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 사채의 인수, 납입</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>5</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 사채등기</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>6</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 전환청구/신주인수권행사</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>7</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>⦁ 전환/신주발행등기</span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <div className="title-box">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>필요서류(회사별)</span>
                      </div>
                      <div className="rt-4" />
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="cont1">
                          <span>
                            ⦁ 이사과반수의 인감도장, 인감증명서 1통
                            <br /> <br />
                            ⦁ 주주(출자)명부, 신문공고문(당사 대행)
                            <br /> <br />⦁ 이사수 2인 이하인 회사는 주식수 25%
                            이상 주주의 인감도장 및 인감증명서 1통
                            주민등록초본1통
                            <br /> <br />⦁ 주주명부, 사채금 완납증명서
                            <br /> <br />⦁ 법인도장, 법인인감증명서 1통,
                            정관사본, 사업자등록증사본
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        value: 1,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>우선주,상환주</span>
                  </div>
                  <div className="content">
                    <span>
                      주식에 투자유인 동기를 다양하게 부여하여 주주의 모집을
                      용이하게 하고 자금조달의 신속성을 꾀하기 위해 보통주 외에
                      우선주, 후배주, 혼합주 등 수종의 주식을 발행할 수 있다. 이
                      중 우선주는 이익배당 또는 잔여재산 분배에 있어 다른 주주에
                      우선하여 배당을 받을 수 있는 주식이며 정관으로
                      최저배당율을 정하고(상법 제 344조 2항), 구체적인 사항은
                      이사회에 위임하고 있다. 한편 상환주식이란 이익배당에 관한
                      우선주에 대해 회사의 이익으로 소각할 것에 대한 특수한
                      약정이 부가된 주식을 말한다(상법 제345조). 즉, 회사는
                      우선주를 발행하여 자금조달을 용이하게 하는 한편 상환약정을
                      두어 회사자금 사정이 개선되면 이를 상환하여 주주의
                      배당압박에서 벗어날 수 있다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>전환주식 발행</span>
                  </div>
                  <div className="content">
                    <span>
                      주주의 청구에 의해 우선주를 보통주로 또는 보통주를
                      우선주로 전환할 수 있는 권한이 부여된 주식을
                      전환주식이라고 한다(상법 제346조). 만약, 우선주 1주에 대해
                      보통주 1.5주를 발행 받을 수 있는 옵션이 부여되어 있다면
                      회사는 훨씬더 용이하게 우선주의 주주를 모집할 수 있으며
                      이를 소유한 우선주의 주주는 회사사정에 따라 보통주로
                      전환하여 더 많은 이익배당에 참여할 수 있는 투자기회를
                      부여받게 된다. 거꾸로 보통주의 주주에게 전환권이 있다면
                      배당에 만족이 없을 경우 우선주로 전환하여 우선배당 받을 수
                      있으므로 주식가치에 대한 만족을 가져올 수 있다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 2,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>개념</span>
                    </div>
                    <div className="content">
                      <span>
                        회사는 주주총회의 결의로 회사의 임원 또는 직원에게 장래
                        일정한 시기에 이르러 예정된 가격(행사가액)에 회사가
                        보유하고 있는 자기주식 또는 새로 발행하는 신주를
                        취득하게 할 수 있다(상법 제340조의2). 이는 회사의 중요
                        임원 또는 직원에게 회사 주식가치 상승에 대한 최선의
                        노력을 다하도록 동기부여함으로써 임직원에게 경제적
                        보상기회를 제공할 뿐만 아니라 회사의 영업실적도
                        좋아지므로 결과적으로 모든 주주에게 이득이 되는
                        제도이다. 그러나 이에 대한 남용은 회사자본충실의 원칙을
                        저해하고 기존 주주의 지분율 감소에 따른 불이익 등의
                        문제가 발생하므로 반드시 정관소정의 요건을 따르도록
                        규정하고 있다.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>절차</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>⦁ 정관규정</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              정관에 규정할 사항은 ①일정한 경우 선택권을 부여할
                              수 있다는 뜻, ② 선택권의 행사로 발행하거나 양도할
                              주식의 종류와 수, ③선택권을 부여 받을 자의
                              자격요건, ④ 선택권의 행사기간 ⑤일정한 경우 이사회
                              결의로 선택권을 취소할 수 있다는 뜻 등이다.
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>⦁ 주주총회의 특별결의</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              회사는 주주총회에서 ①주식매수선택권을 부여받을
                              자의 성명, ②주식매수선택권의 부여방법,
                              ③주식매수선택권의 행사가액과 조정에 관한 사항,
                              ④주식매수선택권의 행사기간 ⑤주식매수선택권의
                              행사로 발행하거나 양도할 주식의 종류와 수 등을
                              정해야 한다.
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>
                              ⦁ 주식매수선택권자와 회사의 계약체결 및 본점비치
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              주주총회의 결의내용에 따라 회사는 선택권자와
                              계약을 체결하고 상당한 기간내에 계약서를 작성하고
                              이를 행사기간 종료시까지 본점에 비치 해야
                              한다(상법 제340조의3).
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>4</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>⦁ 선택권의 행사</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              선택권은 양도될 수 없으나 상속인이 행사할 수는
                              있다.
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>5</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>⦁ 변경등기</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              선택권의 행사로 신주를 발행한 경우 행사일로부터
                              2주내에 변경등기를 해야 한다.
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <div className="title-box">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>필요서류</span>
                      </div>
                      <div className="rt-4" />
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="cont1">
                          <span>
                            ⦁ 주식수 34% 이상주주의 인감도장, 인감증명서 1통
                            <br /> <br />
                            ⦁ 주주명부, 주식매수선택권자의 도장
                            <br /> <br />⦁ 법인인감도장, 법인인감증명서 1통,
                            정관사본, 사업자등록증사본
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
    ],
  },
  {
    id: 6,
    subMenu: [
      {
        value: 0,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>개념</span>
                    </div>
                    <div className="content">
                      <span>
                        영업활동의 종료인 세무소에 대한 폐업신고와 달리 법인격을
                        소멸하기 위해서는 해산 및 청산절차를 밟아야 하며 이로써
                        법인등기부가 폐쇄된다. 통상 주주총회의 특별결의로
                        해산하며 기타 존립기간만료, 정관상의 사유발생, 합병 또는
                        해산명령에 의해서도 해산된다. 한편 회사는 최후 등기 후
                        5년이 경과한 때 해산의제 되어 법원행정처장의 공고 후
                        해산간주된다(상법 제520조의2).
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type3">
                    <div className="title">
                      <span>절차</span>
                    </div>
                    <div className="rt-top" />
                    <ul className="list-area">
                      <li>
                        <div className="num">
                          <span>1</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title1">
                            <span>
                              주주총회 특별결의로 해산결의 및 청산인 선임
                            </span>
                          </div>
                          <div className="l-cont">
                            <span></span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>2</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>채권자보호절차</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 알고 있는 채권자에 채권신고 최고 및 2개월간의
                              신고기간을 정하여 2회 이상 신문공고
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>3</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>청산인의 신고</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 청산인의 인적사항 및 해산사유를 법원에 신고
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>4</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>자산목록 및 대차대조표 신고</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 회사본점소재지 지방법원에 재산목록 신고
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>5</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>채무 변제 및 잔여재산의 분배</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 공고기간 종료 후 채무변제 및 잔여재산 분배
                            </span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 부채가 자산을 초과하는 경우에는 파산절차로 이행
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>6</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>결산보고서 작성</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 잔여재산 분배 후 결산보고서 작성하여 주주총회의
                              승인
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                      <li>
                        <div className="num">
                          <span>7</span>
                        </div>
                        <div className="l-content">
                          <div className="l-title">
                            <span>청산종결등기</span>
                          </div>
                          <div className="l-cont">
                            <span>
                              ⦁ 승인된 결산보고서 및 주주총회의사록을 첨부하여
                              청산종결 등기신청
                            </span>
                          </div>
                        </div>
                      </li>
                      <div className="rt-btm" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <div className="title-box">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>필요서류</span>
                      </div>
                      <div className="rt-4" />
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="cont1">
                          <span>
                            ⦁ 주식수 34% 이상주주의 인감도장, 인감증명서 2통
                            <br /> <br />
                            ⦁ 주주명부, 신문공고문2회(당사 대행)
                            <br /> <br />⦁ 청산인의 인감도장, 인감증명서 1통,
                            주민등록초본1통
                            <br /> <br />⦁ 대차대조표 및 재산목록, 법인인감카드
                            <br /> <br />⦁ 법인인감도장, 법인인감증명서 1통,
                            정관사본, 사업자등록증사본
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        value: 1,
        data: (
          <>
            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type1">
                    <div className="title">
                      <span>회사의 계속</span>
                    </div>
                    <div className="content">
                      <span>
                        회사가 존립기간만료나 정관사유에 따라 주주총회의
                        특별결의로 해산한 경우 또는 청산중인 회사는 주주총회의
                        특별결의에 따라 회사를 계속할 수 있다(상법 제519조).
                        또한 휴면회사로서 해산의제 된 경우에도 청산종결간주되지
                        아니한 이상 주주총회의 특별결의로 회사를 계속할 수 있다.
                        회사는 계속결의 및 계속등기로 해산전의 상태로 복귀하여
                        존속하게 되며, 청산인은 그 활동이 종료되어 주말되고 신규
                        임원진이 새로운 기관으로서 등기경료된다.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <span>회사의 부활</span>
                    </div>
                    <div className="content">
                      <span>
                        청산종결된 회사는 어떠한 경우에도 회사계속을 할수는
                        없으나, 미청산 잔여재산이 남아 있음을 증명하여
                        잔여재산을 청산하기 위한 목적 범위 내에서 회사를
                        부활시키고 등기용지를 회복할 수 있다. 특히 미청산 재산이
                        부동산인 경우 이를 처분하기 위해서는 법인인감증명서의
                        발급이 필수적이므로 반드시 회사를 부활시켜야 인감증명서
                        등의 발급이 가능하며 등기신청인의 자격을 회복할 수 있다.
                        <br />
                        <br />
                        <br />
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="content-box">
                <div className="bc">
                  <div className="type2">
                    <div className="title">
                      <div className="title-box">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>필요서류</span>
                      </div>
                      <div className="rt-4" />
                    </div>
                    <div className="chart-area">
                      <div className="doc">
                        <div className="cont1">
                          <span>
                            ⦁ 주식수 34% 이상주주의 인감도장, 인감증명서 2통
                            <br /> <br />
                            ⦁ 주주명부, 임원진의 인감도장, 인감증명서 2통,
                            주민등록초본 1통
                            <br /> <br />⦁ 미청산 경위서(부활등기의 경우),
                            법인인감카드
                            <br /> <br />⦁ 법인인감도장, 법인인감증명서 1통,
                            정관사본, 사업자등록증사본
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
    ],
  },
  {
    id: 7,
    subMenu: [
      {
        value: 0,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>개념</span>
                  </div>
                  <div className="content">
                    <span>
                      비영리법인은 학술, 종교, 자선, 기예, 사교등 영리아닌
                      사업을 목적으로 하는 법인으로서 사람의 결집체를
                      사단법인이라고 하고 재산의 결집체를 재단법인 이라고
                      한다(민법 제32조). 사단법인은 공동의 목적을 가진 사람들이
                      모여 법인의 실체를 구성하고 등기신청을 통해 법인격을
                      취득하게 되는 절차이며, 재단법인은 재산을 출연한 자가
                      일정한 목적을 위해 재산운용에 대한 정관 및 이사회를
                      구성하여 실체형성 후 등기신청을 하게 된다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>절차</span>
                  </div>
                  <div className="content">
                    <span>
                      비영리법인 설립을 위해서는 ①주무관청의 허가(행정청) ②
                      법인등기(본점관할등기소) ③고유번호등록증발급(본점관할
                      세무소)의 순서로 진행 되며 업무의 대부분은 주무관청의
                      허가절차에 집중되어 있다. 주무관청의 허가를 받기 위해서는
                      우선 설립하고자 하는 법인의 설립취지를 명확히 하여
                      허가소관청부터 특정해야 한다. 아울러 소관청이 확인되면
                      관련서류(설립취지서, 정관, 사업계획서, 수지예산서등)을
                      제출하여 심사를 받게 되며, 그 결과 소관청으로부터
                      허가공문, 설립허가증 및 인증된 정관 등을 수령 받게 된다.
                    </span>
                  </div>
                  <div className="info">
                    <span>
                      저희 사무소는 (재)이랜드재단, (재)안풍장학재단,
                      (사)한국해비타트, (사)한일해저터널연구원,
                      (사)동북아전략연구원 등 100여개 비영리법인에 대한 설립등기
                      및 자문을 수행하고 있습니다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 1,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>기타법인</span>
                  </div>
                  <div className="content">
                    <span>
                      민법이외의 개별 법률에 설립근거를 두 법인으로서 영리 아닌
                      사업을 목적으로 하는 많은 법인들이 있다. 예컨대
                      ①중소기업자의 지위향상 및 협동조직의 육성을 목적으로
                      설립된 협동조합(중소기업협동조합법) ②근로자의 생활안정과
                      복지증진을 목적으로 설립된 사내근로복지기금(근로복지
                      기본법 제50조) ③기타 보건복지부장관의 허가를 받아 설립되는
                      사회복지법인(사회복지사업법 제16조) 및
                      평생교육진흥원(평생교육법 제19조)등의 법인들은 모두 개별
                      법률에 설립근거를 두고 있으며 이에 대한 조직운영 및
                      등기사항등도 모두 관련 법령에 따라 규율되고 있다.
                    </span>
                  </div>
                  <div className="info">
                    <span>
                      저희 사무소는 사회복지법인 한국컴패션, 열매나눔재단,
                      (재)2012여수세계박람회조직위원회(해양수산부주관),
                      평생교육진흥원(교육과학기술부주관) 등에 대한 설립등기 및
                      자문을 수행하고 있습니다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    id: 8,
    subMenu: [
      {
        value: 0,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>소유권보존등기</span>
                  </div>
                  <div className="content">
                    <span>
                      소유권보존등기란 건물신축 후 건축물대장을 근거로 등기소에
                      최초로 등기용지를 개설하는 등기를 말하며, 통상 건축물대장
                      편성 및 건축물사용 승인서를 수령하게 되면 건축물을 취득한
                      것으로 보게 되므로 60일내에 취득세 납부를 해야 한다.
                      취득세 납부를 위해서는 건축물의 기준과표 산정을 위해
                      건축물신축 비용 신고서를 제출하며 관할관청은 이를 근거로
                      과표산정 후 취득세고지서를 발급하게 된다. 한편
                      보존등기신청인은 취득세납부 후 영수증 등을 첨부하여 물건지
                      관할등기소에 보존등기를 신청한다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>소유권이전등기</span>
                  </div>
                  <div className="content">
                    <span>
                      토지 및 건물에 대해 매매, 증여, 경매 등의 등기원인에 따른
                      소유권이전등기를 신청하게 된다. 통상 매매란 당사자간
                      유상거래를 통해 이전하는 절차를 말하고 증여란 무상으로
                      대가 없이 이전하는 등기를 말한다. 특히 유상거래 물건이
                      주택인 경우 계약일로부터 15일내에 주택거래신고
                      (주택거래신고지역에 한함)를 해야 하고 기타 물건의 경우
                      부동산거래신고를 60일내에 해야 한다. 단 이전등기신청은
                      취득일(통상 잔금기준일로 해석됨)로부터 60일내에
                      관할등기소에 신청해야 한다(부동산등기특별조치법 제2조).
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>필요서류</span>
                  </div>
                  <div className="chart-area">
                    <div className="doc">
                      <div className="rt">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>부동산매매의 경우</span>
                      </div>
                      <div className="rt-3" />
                      <div className="cont1">
                        <span>
                          ⦁ 매도인의 인감도장, 부동산매도용인감증명서 1통,
                          주민등록초본 1통, 등기권리증, 신분증사본
                          <br /> <br />
                          ⦁ 매수인의 도장, 주민등록초본, 신분증사본
                          <br /> <br />⦁ 부동산거래신고필증
                        </span>
                      </div>
                    </div>
                    <div className="doc">
                      <div className="rt">
                        <img src="/assets/images/common/business_need02.png" />
                        <span>증여의 경우</span>
                      </div>
                      <div className="rt-3" />
                      <div className="cont1">
                        <span>
                          ⦁ 증여자의 인감도장,인감증명서 1통, 주민등록초본1통,
                          등기권리증, 신분증사본
                          <br /> <br />⦁ 수증자의 도장, 주민등록초본, 신분증사본
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 1,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>상속등기</span>
                  </div>
                  <div className="content">
                    <span>
                      상속에 의해 취득한 부동산을 상속인명의로 이전하는 절차를
                      말한다. 통상 법정지분(민법 제1009조)에 의한 상속등기와
                      협의분할에 의한 상속 등기를 하게되며, 협의분할 경우에는
                      공동상속인들이 상속재산분할협의서에 인감날인 후
                      인감증명서를 첨부해야 한다. 한편 공동상속인 중 친권자와
                      이행상반되는 미성년의 자가 있는 경우에는 가정법원의
                      특별대리인선임 심판서를 받아 동 대리인이 미성년자를
                      대리하여 협의분할서 인감날인 후 대리인의 인감증명서를
                      첨부해야 한다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>외국인 상속</span>
                  </div>
                  <div className="content">
                    <span>
                      재외국민 또는 외국국적 취득자가 상속재산을 협의취득하는
                      경우에도 인감증명이나 이에 준하는 서면을 제출해야 한다.
                      재외국민(영주권자등)의 경우에는 관할 재외공관으로부터
                      인감증명발급에 대한 위임장을 공증받아 국내로 우송하여
                      수임인으로 하여금 업무처리하게 할 수도 있고 또는
                      상속재산분할협의서상의 서명이 본인의 것을 증명하는
                      재외공관의 확인서나 이에 관한 공정증서로 대신할 수 있다.
                      한편 인감날인제도가 있는 외국인(일본)은 협의서에 인감날인
                      및 인감증명서를 첨부할 수 있으나 인감날인제도가 없는
                      외국인의 경우 협의서상의 서명 또는 날인이 본인의 것임을
                      증명하는 본국의 공정증서로 대신할 수 있다.
                    </span>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>필요서류</span>
                  </div>
                  <div className="sub-info">
                    <span>
                      * 상기 서류는 모두 등기 신청일 기준 최근 3개월 내에 발급된
                      것이어야 함.
                    </span>
                  </div>

                  <div className="chart-area">
                    <div className="doc">
                      <div className="rt">
                        <img src="/assets/images/common/business_need01.png" />
                        <span>피상속인(사망자)의 서류</span>
                      </div>
                      <div className="rt-3" />
                      <div className="cont1">
                        <span>
                          ⦁ 주민등록말소자초본, 기본증명서, 가족관계증명서,
                          혼인관계증명서, 입양관계증명서, 친양자관계증명서,
                          호주의 제적등본 각1통
                        </span>
                      </div>
                    </div>
                    <div className="doc">
                      <div className="rt">
                        <img src="/assets/images/common/business_need02.png" />
                        <span>상속인의 서류</span>
                      </div>
                      <div className="rt-3" />
                      <div className="cont1">
                        <span>
                          ⦁ 배우자의 경우 : 가족관계증명서, 혼인관계증명서,
                          주민등록등본, 인감증명서 각 1통, 인감도장, 신분증사본
                          <br /> <br />⦁ 자녀인 경우 : 기본증명서,
                          가족관계증명서, 주민등록등본, 인감증명서 각 1통,
                          인감도장, 신분증사본
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        value: 2,
        data: (
          <div className="container">
            <div className="content-box">
              <div className="bc">
                <div className="type1">
                  <div className="title">
                    <span>근저당권</span>
                  </div>
                  <div className="content">
                    <span>
                      계속적 거래관계에서 발생하는 다수의 불특정 채권을
                      결산기까지 일정한 한도로 담보하는 권리가 근저당권이며 이를
                      공시하기 위해 근저당권설정 등기를 하게 된다. 근저당권자는
                      담보사고의 경우 근저당권에 기한 임의경매신청을 통해
                      설정순위에 따른 우선변제를 받게 된다.
                    </span>
                  </div>
                  <div className="chart-area">
                    <div className="doc">
                      <div className="rt">
                        <img src="/assets/images/common/business_need02.png" />{" "}
                        <span>첨부서류</span>
                      </div>
                      <div className="rt-3" />
                      <div className="cont1">
                        <span>
                          ⦁ 근저당권의무자 : 인감도장, 인감증명서, 등기권리증,
                          주민등록초본, 신분증사본
                          <br />⦁ 근저당권자 : 도장(인감아니어도 무방),
                          주민등록초본
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>전세권</span>
                  </div>
                  <div className="content">
                    <span>
                      전세금을 지급하고 타인의 부동산을 점유하여 그 부동산의
                      용도에 따라 사용수익하는 권리를 전세권이라고 한다.
                      전세권은 용익물권으로서 특정 공간을 이용하는 권리이지만
                      전세권설정등기를 통해 후순위권리자 또는 채권자보다
                      전세금의 우선변제를 받을 권리가 있으므로 주택 및 사무실에
                      대한 전세계약 후 전세금(보증금)지급을 담보하기 위한
                      방법으로 많이 활용된다(민법 제303조).
                    </span>
                  </div>
                  <div className="chart-area">
                    <div className="doc">
                      <div className="rt">
                        <img src="/assets/images/common/business_need02.png" />{" "}
                        <span>첨부서류</span>
                      </div>
                      <div className="rt-3" />
                      <div className="cont1">
                        <span>
                          ⦁ 전세권의무자 : 인감도장, 인감증명서, 등기권리증,
                          주민등록초본, 신분증사본
                          <br />⦁ 전세권자 : 도장(인감아니어도 무방),
                          주민등록초본
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="type2">
                  <div className="title">
                    <span>가등기</span>
                  </div>
                  <div className="content">
                    <span>
                      부동산매매계약 후 잔금납부시까지 매도인이 매매 목적물을
                      타에 처분하는 것을 방지하기 위한 목적으로 하는
                      가등기(소유권등기청구권가등기)와 채권담보를 목적으로
                      채무자(또는 제3자)소유의 부동산에 대물반환예약을 체결하고
                      이행기에 채무의 변제가 없는 경우 채권자의 예약완결권
                      행사에 따른 소유권이전등기청구권을 보전하는
                      가등기(담보가등기)가 있다.
                    </span>
                  </div>
                  <div className="chart-area">
                    <div className="doc">
                      <div className="rt">
                        <img src="/assets/images/common/business_need02.png" />
                        <span>첨부서류</span>
                      </div>
                      <div className="rt-3" />
                      <div className="cont1">
                        <span>
                          ⦁ 가등기의무자 : 인감도장, 인감증명서, 등기권리증,
                          주민등록초본, 신분증사본
                          <br />⦁ 가등기권자 : 도장(인감아니어도 무방),
                          주민등록초본
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  },
];

export default BusinessContent;
