import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "assets/css/main/visual.css";

function Visual() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      <div className="visual">
        <img src="/assets/images/main/main01.png" />
        <div className="slide-content">
          <div className="content-box">
            <div className="content">
              <div className="tb1">
                <span>We wish your successful business</span>
              </div>
              <div className="tb2">
                <span className="bl">기업법무의 정도를 지향</span>
                <span>하는 인정법무사 입니다.</span>
              </div>
              <div className="tb3">
                <div className="rt" />
                <div className="sp">
                  <span>
                    기업법무의 정도를 지향하는 상사회사 전문 사무소로서 20여년간
                    수행한 다양한 업무경험을 바탕으로
                    <br /> 법인설립에서 상장까지 회사업무의 질 높은 서비스를
                    제공하며 귀사의 친절하고 편안한 법무 동반자가
                    되어드리겠습니다.
                  </span>
                </div>
              </div>
              {/* <div className="innerBg">
              <img src="assets/images/main/m_visual.png" />
              <div className="mMainLayer">
                <img src="assets/images/main/m_layer.png" />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="visual">
        <img src="/assets/images/main/main02.png" />
        <div className="slide-content">
          <div className="content-box">
            <div className="content">
              <div className="tb1">
                <span>We wish your successful business</span>
              </div>
              <div className="tb2">
                <span className="bl">기업법무의 정도를 지향</span>
                <span>하는 인정법무사 입니다.</span>
              </div>
              <div className="tb3">
                <div className="rt" />
                <div className="sp">
                  <span>
                    기업법무의 정도를 지향하는 상사회사 전문 사무소로서 20여년간
                    수행한 다양한 업무경험을 바탕으로
                    <br /> 법인설립에서 상장까지 회사업무의 질 높은 서비스를
                    제공하며 귀사의 친절하고 편안한 법무 동반자가
                    되어드리겠습니다.
                  </span>
                </div>
              </div>
              {/* <div className="innerBg">
            <img src="assets/images/main/m_visual.png" />
            <div className="mMainLayer">
              <img src="assets/images/main/m_layer.png" />
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="visual">
        <img src="/assets/images/main/main03.png" />
        <div className="slide-content">
          <div className="content-box">
            <div className="content">
              <div className="tb1">
                <span>We wish your successful business</span>
              </div>
              <div className="tb2">
                <span className="bl">기업법무의 정도를 지향</span>
                <span>하는 인정법무사 입니다.</span>
              </div>
              <div className="tb3">
                <div className="rt" />
                <div className="sp">
                  <span>
                    기업법무의 정도를 지향하는 상사회사 전문 사무소로서 20여년간
                    수행한 다양한 업무경험을 바탕으로
                    <br /> 법인설립에서 상장까지 회사업무의 질 높은 서비스를
                    제공하며 귀사의 친절하고 편안한 법무 동반자가
                    되어드리겠습니다.
                  </span>
                </div>
              </div>
              {/* <div className="innerBg">
          <img src="assets/images/main/m_visual.png" />
          <div className="mMainLayer">
            <img src="assets/images/main/m_layer.png" />
          </div>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
}

export default Visual;
