import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";

const SilentRefresh = ({ children }) => {
  const { urls, axiosFetch } = useAxiosFunction();
  const setUserInfo = useSetRecoilState(userInfoState);
  const rToken = localStorage.getItem("rToken");

  useEffect(() => {
    if (rToken != null) {
      const reqData = {
        refresh_token: rToken,
      };

      axiosFetch({
        method: "POST",
        url: urls.rTokenCheck,
        requestConfig: reqData,
      })
        .then((res) => {
          setUserInfo({
            isLogin: true,
            aToken: res.access_token,
            userInfo: res.user_id,
          });
        })
        .catch((err) => {
          console.log("err > ", JSON.stringify(err));
          setUserInfo({
            isLogin: false,
            aToken: null,
            userInfo: null,
          });
        });
    }
  }, []);

  return children;
};

export { SilentRefresh };
